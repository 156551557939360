import React from 'react';
import ClientIndex from 'src/views/client/ClientView';
import SomethingWentWrong from './components/SomethingWentWrong';
import FailurePaymentPage from './views/paymentpages/FailurePaymentPage';
import SuccessfulPaymentPage from './views/paymentpages/SuccessfulPaymentPage';
import BrokerRequestDetails from './views/requests/RequestView/requestDetails';
import RequestDetails2 from './views/customerRequest/customerRequestView/RequestDetails2';
import BrokerQuoteDetails from './views/quotes/quoteView/quoteDetails';
const AccountView = React.lazy(() => import('src/views/account/AccountView'));
const DashboardView = React.lazy(() =>
  import('src/views/reports/DashboardView')
);
const CustomerListView = React.lazy(() =>
  import('src/views/customer/CustomerListView')
);

const SettingsView = React.lazy(() =>
  import('src/views/settings/SettingsView')
);

const UpdatePasswordView = React.lazy(() =>
  import('src/views/auth/UpdatePasswordView')
);

const RedemptionView = React.lazy(() =>
  import('src/views/redemption/redemptionListView')
);

const OrderView = React.lazy(() => import('src/views/orders/OrdersListView'));

const QuoteView = React.lazy(() => import('src/views/quotes/quoteView'));

const ProductView = React.lazy(() =>
  import('src/views/product/ProductListView')
);

const PolicytView = React.lazy(() => import('src/views/policy/PolicyView'));

const RequestView = React.lazy(() => import('src/views/requests/RequestView'));

const CLientView = React.lazy(() => import('src/views/client/ClientView'));

const ClientRequestView = React.lazy(() =>
  import('src/views/customerRequest/customerRequestView')
);

const ClientRequestListView = React.lazy(() =>
  import('src/views/customerRequest/customerRequestView/RequestDetails')
);

const CustomerPolicyView = React.lazy(() =>
  import('src/views/CustomerPolicy/CustomerPolicyView')
);

const CustomerAcceptedQuotesView = React.lazy(() =>
  import('src/views/accepted_quote/quoteView')
);

export const PrivateRoutes = [
  // {
  //   path: '/dashboard',
  //   name: 'customerdashboard',
  //   exact: true,
  //   component: CLientView,
  //   layout: '/customer'
  // },
  {
    path: '/myrequests',
    name: 'customerrequests',
    component: ClientRequestView,
    layout: '/customer'
  },
  {
    path: '/acceptedquotes',
    name: 'customeracceptedquotes',
    component: CustomerAcceptedQuotesView,
    layout: '/customer'
  },
  {
    path: '/requestdetails',
    name: 'customerrequestdetails',
    component: ClientRequestListView,
    layout: '/customer'
  },
  {
    path: '/homedetails',
    name: 'customerrequestdetails2',
    component: RequestDetails2,
    layout: '/customer'
  },
  {
    path: '/customerpolicy',
    name: 'customercustomerpolicy',
    component: CustomerPolicyView,
    layout: '/customer'
  },
  {
    path: '/profilesettings',
    name: 'customerAccount',
    component: AccountView,
    layout: '/customer'
  }
];

export const proxyPrivateRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    exact: true,
    component: DashboardView,
    layout: '/intermediary'
  },
  {
    path: '/policies',
    name: 'dashboardpolicy',
    component: PolicytView,
    layout: '/intermediary'
  },
  {
    path: '/quotes',
    name: 'dashboardquote',
    component: QuoteView,
    layout: '/intermediary'
  },
  {
    path: '/quote-details',
    name: 'dashboardquote',
    component: BrokerQuoteDetails,
    layout: '/intermediary'
  },
  {
    path: '/requests',
    name: 'dashboardquotedetails',
    component: RequestView,
    layout: '/intermediary'
  },
  {
    path: '/request-detail',
    name: 'dashboardrequestsdetails',
    component: BrokerRequestDetails,
    layout: '/intermediary'
  },
  {
    path: '/settings',
    name: 'dashboardsettings',
    component: SettingsView,
    layout: '/intermediary'
  },
  {
    path: '/report',
    name: 'dashboardresport',
    component: SettingsView,
    layout: '/intermediary'
  }
];
