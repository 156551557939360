import React, { useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import WebFont from 'webfontloader'
import './styles.css'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const FormOptionsV2 = ({
  label,
  handleChange,
  type,
  value,
  name,
  options,
  style,
  disabled,
  onBlur,
  id
}) => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    })
  }, [])

  return (
    <>
      <div
        style={{
          marginBottom: 15
        }}
      >
        {name?.includes('buy_back') ? (
          <OverlayTrigger
            placement='right'
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <FormControl className='font-loader' fullWidth>
              <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id={id}
                value={value}
                label={label}
                onChange={handleChange}
                style={style}
                // onInput={onInput}
                onBlur={onBlur}
                name={name}
              >
                <MenuItem disabled value={null}> Select an option</MenuItem>
                {options &&
                  options?.map(option => (
                    <MenuItem
                      className='font-loader'
                      key={option.id}
                      value={name === 'vehicle_make' || name === "model_id" || name === 'country' ? option.name : option.id}
                    >
                      {' '}
                      {option.name ? option.name : option.month + ' Month(s)'}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </OverlayTrigger>
        ) : (
          <FormControl className='font-loader' fullWidth>
            <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id={id}
              value={value}
              label={label}
              onChange={handleChange}
              style={style}
              onBlur={onBlur}
              name={name}
            >
              <MenuItem value={null}> Select an option</MenuItem>
              {options &&
                options?.map(option => (
                  <MenuItem
                    className='font-loader'
                    selected={value === option.id || value ===  option.name}
                    key={option.id}
                    value={name === 'vehicle_make' || name === "model_id" || name === 'country' || name === 'building_type' ? option.name : option.id}
                  >
                    {' '}
                    {option.name ? option.name : option.month + ' Month(s)'}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </div>
    </>
  )
}

const renderTooltip = props => {
  return (
    <Tooltip id='button-tooltip' {...props}>
      This means you would pay a higher premium, but you would pay less
      out-of-pocket for a claim.
    </Tooltip>
  )
}

export default FormOptionsV2
