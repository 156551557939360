import React, { useEffect, useState } from 'react';
import './styles.css';
import { AiOutlineCar } from 'react-icons/ai';
import LinearProgress from '@mui/material/LinearProgress';
import { BiUserPlus } from 'react-icons/bi';
import { AiOutlineBook, AiOutlineArrowLeft } from 'react-icons/ai';
import FormInput from './FormInput';
import FormOptions from './FormOptions';
import { Form } from 'react-bootstrap';
import leftGrid from '../../assets/img/Repeat_Grid_1.png';
import { Modal } from 'react-bootstrap';
import {
  vehicleinfo,
  Policyinfo,
  profileinfoLoggedIn,
  months
} from './AllQuestions';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { formatDate, addDays } from 'src/utils/FormatDate';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'src/Context';
import Button from 'react-bootstrap/Button';
import { SUBMIT_REGISTERED_CUSTOMER_REQUEST } from 'src/Graphql/Mutations';
import { CUSTOMER_PIE_PLOT } from 'src/Graphql/Queries';
import { SuccessNotification } from 'src/components/Notification';
import { useMutation, gql, useLazyQuery, useQuery } from '@apollo/client';
import customLoading from '../../assets/img/logo_animation.gif';
import { isMobile } from 'react-device-detect';
import '../../views/auth/styles.css';
import WebFont from 'webfontloader';
import myPolicyLogo from '../../assets/img/MyPolicy.svg';
import { ErrorNotification } from 'src/components/Notification';
import FormOptionsV2 from './FormOptionsV2';
import FileInput from './FileInput';

const mysteps = [vehicleinfo];
const lastSet = mysteps[mysteps.length - 1];

const GET_VEHICLE_MODEL_BY_PARENT_ID = gql`
  query getVehicleModelByParentID($parent_ID: Int) {
    references(where: { parent_id: { _eq: $parent_ID } }) {
      name
    }
  }
`;

const GET_VEHICLE_MAKES = gql`
  query getVehicleMakes {
    references(where: { is_parent: { _eq: true } }) {
      id
      name
    }
  }
`;

const GET_MOTOR_INSURANCE_OPTIONS = gql`
  query getMotorOptions {
    request_types(where: { id: { _eq: 1 } }) {
      properties
    }
  }
`;

const AnimatedCardLoggedIn = () => {
  const context = useAppContext();
  const { user } = context;

  const [vehicle_model, setVehicleModel] = useState(null);
  const [period, setPeriod] = useState('yyyy/mm/dd');
  const [progress, setProgress] = useState([0, 0, 0]);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [questionSetIndex, setquestionSetIndex] = useState(0);
  const [insuranceType, setInsuranceType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const navigate = useNavigate();
  const [selectedOption1, setSelectedOption1] = useState();

  const FILE_SIZE = 10000000;
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf'
  ];

  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);

  const COMPREHENSIVE_PROD_ID = 1;

  let currentSectionArray = [];

  const [getModelsByParentID] = useLazyQuery(GET_VEHICLE_MODEL_BY_PARENT_ID, {
    onCompleted: data => {
      setVehicleModel(data?.references);
    }
  });

  const { data: vehicle_makes } = useQuery(GET_VEHICLE_MAKES);
  const { data: motorOptions } = useQuery(GET_MOTOR_INSURANCE_OPTIONS);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      vehicle_make: '',
      model_id: '',
      // color: '',
      // registration_number: '',
      // interval: '',
      // chassis_number: '',
      vehicle_value: '',
      year_of_registration: '',
      insurance_product_id: '',
      // inception_date: '',
      // expiry_date: '',
      // usage_type: '',
      cost_price: '',
      // seats: '',
      year_of_manufacturing: '',
      // increase_thirparty_damage_limit: '',
      // road_worthy_doc: '',
      // repair_state: '',
      // altered_vehicle_design: '',
      // state_of_claim: '',
      // no_claim_years: '',
      // purchase_date: '',
      // engine_capacity: '',
      // other_usage: null,
      buy_back_excess: '',
      // purchase_for_self: '',
      // mileage: '',
      // beneficiary_name: '',
      // beneficiary_email: '',
      // beneficiary_phone_number: '',
      // beneficiary_id: '',
      // motorInsuranceType: ''
    },

    validationSchema: Yup.object({
      insurance_product_id: Yup.string().required(
        'Kindly select an insurance product'
      ),
      // inception_date: Yup.date()
      //   .min(
      //     currentDate,
      //     'Policy Start date must be equal or greater then current date '
      //   )
      //   .required('Kindly indicate when you want your insurance to start'),
      vehicle_make: Yup.string().required('Kindly indicate your vehicle make'),
      model_id: Yup.string().required('Kindly select your vehicle model'),
      // color: Yup.string().required('Kindy state the color of your vehicle'),
      // registration_number: Yup.string().required(
      //   'Kindly provide the registration number of your vehicle'
      // ),
      // chassis_number: Yup.string().required(
      //   'Kindly provide the chassiss number'
      // ),
      // interval: Yup.string().required(
      //   'Kindly indicate how long you want your insurance to last'
      // ),
      year_of_registration: Yup.number().required(
        'Kindly indicate the year of registration '
      ),

      buy_back_excess: Yup.string().required(
        'Kindly indicate if you want buy back excess or not'
      ),

      // usage_type: Yup.string().when('insurance_product_id', {
      //   is: insurance_product_id =>
      //     Number(insurance_product_id) === COMPREHENSIVE_PROD_ID,
      //   then: Yup.string().required('Kindly select a usage type')
      // }),
      // cost_price: Yup.number().required(
      //   'Kindly provide the cost of your vehicle'
      // ),
      // cost_price: Yup.number().when('insurance_product_id', {
      //   is: insurance_product_id =>
      //     Number(insurance_product_id) === COMPREHENSIVE_PROD_ID,
      //   then: Yup.number().required('Kindly provide the cost of your vehicle')
      // }),

      // seats: Yup.number().when('insurance_product_id', {
      //   is: insurance_product_id =>
      //     Number(insurance_product_id) === COMPREHENSIVE_PROD_ID,
      //   then: Yup.number().required(
      //     'Kindly provide the number of seats in your vehicle'
      //   )
      // }),

      // increase_thirparty_damage_limit: Yup.string().when(
      //   'insurance_product_id',
      //   {
      //     is: insurance_product_id =>
      //       Number(insurance_product_id) === COMPREHENSIVE_PROD_ID,
      //     then: Yup.string().required(
      //       'Kindly select a third party damage option'
      //     )
      //   }
      // ),
      vehicle_value: Yup.string()
      .matches(
        /[0-9]/,
        'Must contain only digit characters'
      )
      .required('Required'),
      year_of_manufacturing: Yup.number().required(
        'Kindly select the year your vehicle was manufactured'
      )

      // year_of_manufacturing: Yup.number().when('insurance_product_id', {
      //   is: insurance_product_id =>
      //     Number(insurance_product_id) === COMPREHENSIVE_PROD_ID,
      //   then: Yup.number().required(
      //     'Kindly select the year your vehicle was manufactured'
      //   )
      // }),

      // repair_state: Yup.string().when('insurance_product_id', {
      //   is: insurance_product_id =>
      //     Number(insurance_product_id) === COMPREHENSIVE_PROD_ID,
      //   then: Yup.string().required(
      //     'Kindly indicate the repair state of your vehicle'
      //   )
      // }),

      // road_worthy_doc: Yup.mixed()
      //   .required('Please upload a copy of your roadworthy document')
      //   .test(
      //     'fileSize',
      //     'File is too large',

      //     value => value && value.size <= FILE_SIZE
      //   )
      //   .test(
      //     'fileFormat',
      //     'Unsupported Format! we only support (jpg, jpeg, png, pdf)',
      //     value => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      //   ),

      // altered_vehicle_design: Yup.string().when('insurance_product_id', {
      //   is: insurance_product_id =>
      //     Number(insurance_product_id) === COMPREHENSIVE_PROD_ID,
      //   then: Yup.string().required(
      //     'Kindly indicate if your vehicle has been changed from the original design'
      //   )
      // }),

      // state_of_claim: Yup.string().when('insurance_product_id', {
      //   is: insurance_product_id =>
      //     Number(insurance_product_id) === COMPREHENSIVE_PROD_ID,
      //   then: Yup.string().required(
      //     'Kindly indicate if you have ever made a claim'
      //   )
      // }),

      // no_claim_years: Yup.number().when('state_of_claim', {
      //   is: state_of_claim => state_of_claim === 'Yes',
      //   then: Yup.number().required(
      //     'Kindly indicate the number of years since your last claim'
      //   )
      // }),

      // purchase_date: Yup.date().when('insurance_product_id', {
      //   is: insurance_product_id =>
      //     Number(insurance_product_id) === COMPREHENSIVE_PROD_ID,
      //   then: Yup.date().required(
      //     'Kindly indicate the date your vehicle was purchased'
      //   )
      // }),

      // engine_capacity: Yup.number().when('insurance_product_id', {
      //   is: insurance_product_id =>
      //     Number(insurance_product_id) === COMPREHENSIVE_PROD_ID,
      //   then: Yup.number().required(
      //     'Kindly indicate the engine capacity of your vehicle'
      //   )
      // }),

      // mileage: Yup.number().when('insurance_product_id', {
      //   is: insurance_product_id =>
      //     Number(insurance_product_id) === COMPREHENSIVE_PROD_ID,
      //   then: Yup.number().required(
      //     'Kindly indicate the current mileage of your vehicle'
      //   )
      // }),

      // beneficiary_name: Yup.string().when('purchase_for_self', {
      //   is: purchase_for_self => purchase_for_self === 'No',
      //   then: Yup.string().required(
      //     'Kindly provide the name of the beneficiary of the cover'
      //   )
      // }),

      // beneficiary_email: Yup.string()
      //   .email()
      //   .when('purchase_for_self', {
      //     is: purchase_for_self => purchase_for_self === 'No',
      //     then: Yup.string().email('Kindly enter a valid email')
      //   }),

      // beneficiary_phone_number: Yup.string().when('purchase_for_self', {
      //   is: purchase_for_self => purchase_for_self === 'No',
      //   then: Yup.string()
      //     .max(10, 'Must be 10 numbers or less')
      //     .matches(
      //       /[0-9]{3}[0-9]{3}[0-9]{4}/,
      //       'Must contain only digit characters'
      //     )
      //     .required(
      //       'Kindly provide the phone number of the beneficiary of the cover'
      //     )
      // }),

      // beneficiary_id: Yup.string().when('purchase_for_self', {
      //   is: purchase_for_self => purchase_for_self === 'No',
      //   then: Yup.string().required(
      //     'ID number is required of beneficiary or company is required'
      //   )
      //   // .matches(/^[A-Z]{3}-[0-9]{9}-[0-9]{1}$/, 'Invalid ID number')
      // })
    }),

    onSubmit: values => {
      const updatedProgress = [...progress];
      updatedProgress[currentFormIndex] = 100;
      setProgress(updatedProgress);
      // console.log(values)
      confirmSubmission();
    }
  });

  const [
    SubmitMotorPolicyRequest,
    { motorPolicyRequestError, motorPolicyRequestLoading }
  ] = useMutation(SUBMIT_REGISTERED_CUSTOMER_REQUEST, {
    variables: {
      created_by: 'self',
      customer_id: user?.id,
      request_type_id: 1,
      status: 'pending',
      motor_details: formik.values,
      home_details: null
    },
    onCompleted: async data => {
      // let roadworthyFront = await uploadFile(
      //   `road_worthy_front`,
      //   formik.values.road_worthy_doc,
      //   data.insert_requests_one.id
      // )

      // if (roadworthyFront.data.data.id) {
      SuccessNotification('Successfully submitted request');
      // console.log(data.insert_requests_one.id)
      // }
    },
    onError: error => console.log(error),
    refetchQueries: [
      { query: CUSTOMER_PIE_PLOT, variables: { customer_id: user?.id } },
      'getPiePlotData'
    ]
  });

  const handleFile = e => {
    formik.setFieldValue('road_worthy_doc', e.target.files[0]);
    // setSelectedFile(e.currentTarget.files[0])
  };

  const confirmSubmission = () => {
    Swal.fire({
      title: 'Are you sure you want to submit this request?',
      text: "You won't be able to revert this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, accept',
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        SubmitMotorPolicyRequest();
        navigate('/customer');
      }
    });
  };

  const vehicleUses = [
    {
      id: 1,
      name: 'The carriage of goods or samples'
    },
    {
      id: 2,
      name: 'The carriage of passengers for hire or reward'
    },
    {
      id: 3,
      name: 'Use in connection with the motor trade'
    },
    {
      id: 4,
      name: 'Private use i.e. Use by yourself only on your own business'
    }
  ];
  const setInceptionDate = e => {
    const value = e.target.value;
    formik.setFieldValue(
      'inception_date',
      new Date(value).toISOString().substring(0, 10)
    );
  };

  const onBuybackChange = e =>{
    formik.setFieldValue('buy_back_excess',e.target.value)
  }

  const onPeriodInput = e => {
    let initialdate = formik.values['inception_date'];

    if (initialdate) {
      // formik.setFieldValue("expiry_date", addMonthV2(initialdate, interval));
      let finaldate = addDays(initialdate, parseInt(e.target.value));
      let formatteddate = formatDate(finaldate);
      formik.setFieldValue('expiry_date', finaldate);

      setPeriod(formatteddate);
    }

    // console.log(formik.values['inception_date'])
  };

  const onMakeInput = e => {
    let selectedMake = vehicle_makes?.references?.filter(
      make => make?.name == e.target.value
    );

    // 1console.log(selectedMake[0])

    // setVehicleModel(selectedMake[0].id)
    getModelsByParentID({
      variables: {
        parent_ID: selectedMake[0]?.id
      }
    });

    // console.log(selectedMake[0].vehicle_models)
  };

  const setOptions = name => {
    
    if (name === 'vehicle_make') {
      return vehicle_makes?.references;
    } else if (name === 'insurance_product_id') {
      return motorOptions?.request_types[0]?.properties?.products;
    } 
    else if (name === 'buy_back_excess') {
      return [
        {id: "Yes", name: "Yes", value: "Yes"},
        {id: "No", name: "No", value: "No"}
      ]
    }else if (
      name === 'year_of_registration' ||
      name === 'year_of_manufacturing'
    ) {
      const year = new Date().getFullYear();
      const yearsBefore = Array.from(
        new Array(40),
        (val, index) => year - index
      );
      let years = [];
      let index = 0;
      yearsBefore.map(year => {
        let obj = {
          id: year,
          name: year
        };
        years.push(obj);
        index++;
      });
      return years;
    } else if (name === 'model_id') {
      if (formik.values['vehicle_make']) {
        return vehicle_model;
      }
    }
  };

  const stepValidator = () => {
    let validatated = true;
    // console.log("I'm invoked")

    for (let i = 0; i < currentSectionArray.length; i++) {
      if (formik.touched[currentSectionArray[i]] != true) {
        validatated = validatated && false;
        // console.log(currentSectionArray[i] + " Was not touched")
      } else if (formik.errors[currentSectionArray[i]]) {
        validatated = validatated && false;
        // console.log(currentSectionArray[i] + " has errors")
      } else {
        validatated = validatated && true;
        // console.log(currentSectionArray[i] + " is valid")
      }
    }

    if (validatated == false)
      ErrorNotification('Kindly provide all the required information');

    return validatated;
  };

  // const getBase64 = file => {
  //   return new Promise(resolve => {
  //     let fileInfo
  //     let baseURL = ''
  //     // Make new FileReader
  //     let reader = new FileReader()

  //     // Convert the file to base64 text
  //     reader.readAsDataURL(file)

  //     // on reader load somthing...
  //     reader.onload = () => {
  //       // Make a fileInfo Object
  //       // console.log("Called", reader);
  //       baseURL = reader.result.replace('data:', '').replace(/^.+,/, '')

  //       // console.log(baseURL);
  //       resolve(baseURL)
  //     }
  //     // console.log(fileInfo);
  //   })
  // }

  // const uploadFile = async (fieldIdentifier, file, reference_id) => {
  //   let indexOfSlash = file.type.indexOf('/')
  //   let ext = file.type.slice(indexOfSlash + 1, file.type.length)

  //   return getBase64(file).then(res => {
  //     const body = {
  //       key: `${fieldIdentifier}.${ext}`,
  //       type: 'request_image',
  //       contents: res,
  //       reference_id: reference_id,
  //       properties: file.type,
  //       created_by: {
  //         id: reference_id,
  //         username: user?.first_name + ' ' + user?.last_name,
  //         name: user?.first_name + ' ' + user?.last_name
  //       }
  //     }

  //     return axios.post(
  //       `${process.env.REACT_APP_API_BASE_URL}/files/upload`,
  //       body
  //     )
  //   })
  // }

  const increaseProgress = () => {
    if (progress[currentFormIndex] < 100) {
      if (mysteps[currentFormIndex].length > 1) {
        let section = 100 / (mysteps[currentFormIndex].length - 1);
        const updatedProgress = [...progress];
        updatedProgress[currentFormIndex] =
          section + progress[currentFormIndex];
        setProgress(updatedProgress);
      } else if (mysteps[currentFormIndex].length == 1) {
        const updatedProgress = [...progress];
        updatedProgress[currentFormIndex] = 100;
        setProgress(updatedProgress);
      }
    }
  };

  const reduceProgress = () => {
    if (progress[currentFormIndex < 100]) {
      if (questionSetIndex < mysteps[currentFormIndex].length) {
        let section = 100 / (mysteps[currentFormIndex].length - 1);
        const updatedProgress = [...progress];
        updatedProgress[currentFormIndex] =
          progress[currentFormIndex] - section;
        setProgress(updatedProgress);
      } else if (mysteps[currentFormIndex].length == 1) {
        const updatedProgress = [...progress];
        updatedProgress[currentFormIndex] = 0;
        setProgress(updatedProgress);
      }
    }
  };

  const nextStep = e => {
    e.preventDefault();

    if (stepValidator()) {
      currentSectionArray = [];

      if (insuranceType != null && insuranceType != 'Comprehensive') {
        setCurrentFormIndex(currentFormIndex + 1);
        setquestionSetIndex(0);
        const updatedProgress = [...progress];
        updatedProgress[currentFormIndex] = 100;
        setProgress(updatedProgress);
        setInsuranceType(null);
        // console.log(formik.values)
      } else if (questionSetIndex == mysteps[currentFormIndex].length - 1) {
        setCurrentFormIndex(currentFormIndex + 1);
        // console.log(formik.values)
        setquestionSetIndex(0);
      } else if (
        currentFormIndex == mysteps.length - 1 &&
        questionSetIndex == lastSet.length - 1
      ) {
        increaseProgress();
        // console.log(formik.values)
        completeForm();
      } else {
        setquestionSetIndex(questionSetIndex + 1);
        // console.log(formik.values)
        increaseProgress();
      }
    }
  };

  const prevStep = e => {
    e.preventDefault();
    if (questionSetIndex > 0) {
      setquestionSetIndex(questionSetIndex - 1);
      reduceProgress();
    } else if (questionSetIndex == 0 && currentFormIndex > 0) {
      setCurrentFormIndex(currentFormIndex - 1);
      setquestionSetIndex(mysteps[currentFormIndex].length - 1);
      reduceProgress();
    } else if (questionSetIndex > 0 && currentFormIndex == 0) {
      setquestionSetIndex(questionSetIndex - 1);
      reduceProgress();
    }
  };

  const completeForm = () => {
    // alert('FForm Complete');
    increaseProgress();
  };

  const navigateToForm = formIndex => {
    // //Setting the progress of the current form to 0
    // let updatedProgress = [...progress];
    // updatedProgress[currentFormIndex] = 0;
    // updatedProgress[formIndex] = 0
    // setProgress(updatedProgress);

    //Navigating to the selected Form
    setCurrentFormIndex(formIndex); //Setting the selected form index
    setquestionSetIndex(0);
  };

  const makeActive = (currentFormIndex, id) => {
    if (currentFormIndex == id) return 'active-class';
  };

  const iconSelect = id => {
    if (id == 0)
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center'
          }}
        >
          <AiOutlineCar
            onClick={() => navigateToForm(id)}
            className={makeActive(currentFormIndex, id)}
            size={20}
            style={{ position: 'relative', left: isMobile ? 50 : 60 }}
          />
          <span
            style={{
              color: id == currentFormIndex ? 'rgb(46, 121, 233)' : null
            }}
            className="font-loader "
          >
            Vehicle Information
          </span>
        </div>
      );
    else if (id == 1)
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center'
          }}
        >
          <BiUserPlus
            onClick={() => navigateToForm(id)}
            className={makeActive(currentFormIndex, id)}
            size={20}
            style={{ position: 'relative', left: isMobile ? 50 : 60 }}
          />
          <span
            style={{
              color: id == currentFormIndex ? 'rgb(46, 121, 233)' : null
            }}
            className="font-loader "
          >
            User Information
          </span>
        </div>
      );
    // else if (id == 2)
    //   return (
    //     <div
    //       style={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         textAlign: 'center'
    //       }}
    //     >
    //       <BiUserPlus
    //         onClick={() => navigateToForm(id)}
    //         className={makeActive(currentFormIndex, id)}
    //         size={20}
    //         style={{ position: 'relative', left: isMobile ? 55 : 80 }}
    //       />
    //       <span
    //         style={{
    //           color: id == currentFormIndex ? 'rgb(46, 121, 233)' : null
    //         }}
    //         className='font-loader '
    //       >
    //         Policy Holder Information
    //       </span>
    //     </div>
    //   )
  };

  return !motorOptions ? (
    <div>
      <img
        style={{
          position: 'relative',
          marginTop: isMobile ? '80%' : '20%',
          left: isMobile ? '35%' : '45%'
        }}
        src={customLoading}
      />
    </div>
  ) : (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <header
            id="header"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'stretch'
            }}
            className="fixed-top"
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignSelf: 'stretch',
                width: '100%',
                padding: isMobile ? 0 : 5
              }}
            >
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '75%'
                }}
              >
                <div
                  style={{
                    margin: 15,
                    margin: isMobile ? 5 : 15,
                    left: isMobile ? -35 : 0
                  }}
                >
                  <a href="/">
                    <img
                      style={{
                        height: isMobile ? '18px' : '28px',
                        width: isMobile ? '129px' : '259.84px'
                      }}
                      src={myPolicyLogo}
                    />
                  </a>
                </div>
                <div>
                  {isMobile ? (
                    <>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          navigate(-1);
                        }}
                        style={{
                          backgroundColor: '#FFFFFF',
                          border: 'none',
                          fontWeight: 'bold'
                        }}
                      >
                        <AiOutlineArrowLeft
                          size={20}
                          style={{ color: '#2E79E9' }}
                        />
                      </Button>
                    </>
                  ) : (
                    <input
                      className="getstarted font-loader scrollto"
                      onClick={e => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      style={{
                        color: '#F6F8FB',
                        backgroundColor: '#2E79E9',
                        border: 'none',
                        width: '160px',
                        height: '40px',
                        fontSize: '18px'
                      }}
                      type="button"
                      value="Go Back"
                    />
                  )}
                </div>
              </div>
            </div>
          </header>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: isMobile ? 110 : 200,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {isMobile ? (
            <div style={{ marginTop: 20 }}>
              <div>
                <div className="icon">{iconSelect(currentFormIndex)}</div>
                <div>
                  <LinearProgress
                    className="progress-mobile"
                    variant="determinate"
                    value={progress[currentFormIndex]}
                  />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <span
                    className="progress-value"
                    style={{
                      display: 'block'
                    }}
                  >
                    Step {questionSetIndex + 1}/
                    {mysteps[currentFormIndex].length}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <>
              {[0].map(step => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                      marginTop: isMobile ? 15 : null
                    }}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        position: 'relative',
                        left: 5
                      }}
                      className="icon"
                    >
                      {iconSelect(step)}
                    </div>
                    <div>
                      <LinearProgress
                        className="progress"
                        variant="determinate"
                        value={progress[step]}
                        style={{ position: 'relative', left: 15 }}
                      />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <span
                        className="progress-value font-loader"
                        style={{
                          display: currentFormIndex != step ? 'none' : 'block'
                        }}
                      >
                        Step {questionSetIndex + 1}/
                        {mysteps[currentFormIndex].length}
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          )}
          {/* <AiOutlineCar className='active-class' size={20} style={{ marginRight: 25 }} />
                <LinearProgress style={{ width: 300 }} variant="determinate" value={progress} /> */}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <h3
            style={{
              textAlign: 'center',
              marginTop: 20,
              left: isMobile ? 10 : 0
            }}
            className="form-header font-loader"
          >
            {mysteps[currentFormIndex][questionSetIndex].title}
          </h3>
          <div style={{ left: isMobile ? 10 : 0 }} className="form-container">
            <Form>
              {mysteps[currentFormIndex][questionSetIndex].questions.map(
                (current, key) => {
                  currentSectionArray.push(current.name);
                  // console.log(current)

                  // if(current.name === "purchase_for_self"){
                  //   console.log(formik.values[current.name])
                  // }

                  return (
                    <>
                      {current.type === 'select' ? (
                        <FormOptionsV2
                          key={key}
                          type={current.type}
                          name={current.name}
                          label={current.label}
                          handleChange={e => {
                            e.preventDefault();
                            if (current.name === 'vehicle_make') {
                              formik.handleChange(e);
                              onMakeInput(e);
                            } else if (current.name === 'interval') {
                              formik.handleChange(e);
                              onPeriodInput(e);
                            }else if (current.name === 'buy_back_excess') {
                              formik.handleChange(e);
                              onBuybackChange(e);
                            } else if (
                              current.name === 'insurance_product_id'
                            ) {
                              formik.handleChange(e);
                              let prodType = motorOptions?.request_types[0]?.properties?.products?.filter(
                                prod => {
                                  if (prod.id === Number(e.target.value)) {
                                    return prod;
                                  }
                                }
                              );
                              setInsuranceType(prodType[0]?.name);
                              formik.setFieldValue(
                                'motorInsuranceType',
                                prodType[0]?.name
                              );
                              // console.log(prodType[0]?.name)
                            } else {
                              formik.handleChange(e);
                            }
                          }}
                          options={setOptions(current.name)}
                          value={formik.values[current.name]}
                          style={{
                            borderColor:
                              formik.touched[current.name] &&
                              formik.errors[current.name]
                                ? 'red'
                                : null
                          }}
                          onBlur={formik.handleBlur}
                          // onInput={
                          //   current.name === 'vehicle_make'
                          //     ? e => onMakeInput(e)
                          //     : current.name === 'interval'
                          //     ? e => {
                          //         onPeriodInput(e)
                          //       }
                          //     : current.name === 'insurance_product_id'
                          //       ? e => {
                          //         console.log()
                          //           let prodType =
                          //             motorOptions?.request_types[0]?.properties?.products?.filter(
                          //               prod => {
                          //                 if (prod.id === Number(e.target.value)) {
                          //                   return prod
                          //                 }
                          //               }
                          //             )
                          //           setInsuranceType(prodType[0]?.name)
                          //           console.log(prodType[0]?.name)
                          //         }:
                          //       null
                          // }
                        />
                      ) : current.type === 'file' ? (
                        <FileInput
                          placeholder={current.placeholder}
                          name={current.name}
                          label={current.label}
                          handleChange={handleFile}
                          style={{
                            borderColor:
                              formik.touched[current.name] &&
                              formik.errors[current.name]
                                ? 'red'
                                : null
                          }}
                          onBlur={formik.handleBlur}
                        />
                      ) : (
                        <FormInput
                          type={current.type}
                          placeholder={current.placeholder}
                          name={current.name}
                          label={current.label}
                          handleChange={
                            current.name == 'inception_date'
                              ? setInceptionDate
                              : formik.handleChange
                          }
                          value={formik.values[current.name]}
                          style={{
                            borderColor:
                              formik.touched[current.name] &&
                              formik.errors[current.name]
                                ? 'red'
                                : null
                          }}
                          onBlur={formik.handleBlur}
                        />
                      )}
                      {formik.values['expiry_date'] &&
                      current.name === 'interval' ? (
                        <div
                          style={{
                            marginBottom: 15,
                            marginTop: -12
                          }}
                        >
                          <span
                            style={{
                              position: 'relative',
                              fontSize: 13
                            }}
                          >
                            Your policy will expire on{' '}
                            <span style={{ fontWeight: 'bold' }}>{period}</span>
                          </span>
                        </div>
                      ) : null}
                      {formik.touched[current.name] &&
                      formik.errors[current.name] ? (
                        <p
                          style={{
                            color: 'red',
                            fontSize: 12,
                            marginTop: -8,
                            marginBottom: 8
                          }}
                        >
                          {formik.errors[current.name]}
                        </p>
                      ) : null}
                    </>
                  );
                }
              )}
              <>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {questionSetIndex != 0 && (
                    <button
                      className="btn btn-primary font-loader"
                      style={{ width: '25%' }}
                      onClick={e => prevStep(e)}
                    >
                      Back
                    </button>
                  )}
                  {(currentFormIndex == mysteps.length - 1 &&
                    questionSetIndex == lastSet.length - 1) ||
                  formik.values.purchase_for_self === 'Yes' ? (
                    <button
                      className="btn btn-primary font-loader"
                      style={{
                        width:
                          questionSetIndex != 0 && currentFormIndex >= 0
                            ? '65%'
                            : '100%'
                      }}
                      // type='submit'
                      onClick={e => {
                        e.preventDefault();
                        console.log(formik.errors)
                        formik.handleSubmit();
                      }}
                      disabled={motorPolicyRequestLoading}
                    >
                      {motorPolicyRequestLoading ? 'Submitting...' : 'Submit'}
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary font-loader"
                      style={{
                        width:
                          questionSetIndex != 0 && currentFormIndex >= 0
                            ? '65%'
                            : '100%'
                      }}
                      onClick={e => nextStep(e)}
                    >
                      Continue
                    </button>
                  )}
                </div>
              </>
            </Form>
          </div>
        </div>
      </div>{' '}
      {/* :{' '}
      <summaryModal
        formikValues={formik.values}
        showModal={showModal}
        handleClose={handleClose}
      /> */}
    </>
  );
};

export default AnimatedCardLoggedIn;

const summaryModal = ({
  formikValues,
  showModal,
  handleClose,
  submitRequest
}) => {
  console.log(formikValues);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      animation={true}
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Summary Form</Modal.Title>
        <button
          onClick={() => handleClose()}
          type="button"
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
    </Modal>
  );
};
