import React, { useEffect, useState } from 'react'
import './styles.css'
import LinearProgress from '@mui/material/LinearProgress'
import { BiUserPlus, BiBuildingHouse, BiArchive } from 'react-icons/bi'
import { GiHazardSign, GiTakeMyMoney } from 'react-icons/gi'
import {
  AiOutlineBook,
  AiOutlineHome,
  AiOutlineArrowLeft
} from 'react-icons/ai'
import { BsBuilding } from 'react-icons/bs'
import FormInput from './FormInput'
import FormOptions from './FormOptions'
import AddItems from './AddItems'
import { Form } from 'react-bootstrap'
import '../../views/auth/styles.css'
import myPolicyLogo from '../../assets/img/MyPolicy.svg'
import {
  PoilcyDetails,
  Building,
  Content,
  Personal_Accident,
  Public_Liability,
  Rent,
  profileinfoLoggedIn,
  months
} from './BuildQuestionsUpdated'
import { useFormik } from 'formik'
import { formatDate, addDays } from 'src/utils/FormatDate'
import * as Yup from 'yup'
import { useNavigate } from "react-router-dom";
import { useAppContext } from 'src/Context'
import Button from 'react-bootstrap/Button'
import { SUBMIT_REGISTERED_CUSTOMER_REQUEST } from 'src/Graphql/Mutations'
import {
  GET_INSURANCE_PRODUCTS_OPTIONS,
  CUSTOMER_PIE_PLOT
} from 'src/Graphql/Queries'
import { SuccessNotification } from 'src/components/Notification'
import { useMutation, gql, useQuery } from '@apollo/client'
import customLoading from '../../assets/img/logo_animation.gif'
import { useLocalStorageState } from '../Hook'
import AddDeleteTableRows from '../addTableRows/Content/addTableRow'
import AddDeleteTablePeopleRows from '../addTableRows/People/addTableRow'
import MaterialsPicker from './MaterialsPicker'
import Swal from 'sweetalert2'
import WebFont from 'webfontloader'
import { ErrorNotification } from 'src/components/Notification'
import { isMobile } from 'react-device-detect'
import FormOptionsV2 from './FormOptionsV2'

const GET_HOME_OPTIONS = gql`
  query getHomeOwnerOptions {
    request_types(where: { id: { _eq: 2 } }) {
      properties
    }
  }
`

const GET_BUILDING_TYPES = gql`
  query getBuildingTypes {
    references(where: { reference_type_id: { _eq: 3 } }) {
      id
      name
    }
  }
`

const HomeOwnerFormLoggedIn = () => {
  const [period, setPeriod] = useState('yyyy/mm/dd')
  const [progress, setProgress] = useState([])
  const [currentFormIndex, setCurrentFormIndex] = useState(0)
  const [questionSetIndex, setquestionSetIndex] = useState(0)
  const navigate = useNavigate();
  const context = useAppContext()
  const { user } = context

  const [mySteps, setMySteps] = useState([])
  const [lastSet, setLastSet] = useState(null)
  // let lastSet = mySteps[Math.abs(mySteps?.length - 1)]
  const [submit, setSubmit] = useState(false)
  const LOCAL_STORAGE_KEY = 'customLocalStorageKey'

  let currentSectionArray = []

  let currentDate = new Date()
  currentDate.setDate(currentDate.getDate() - 1)

  const { data: homeOptions, loading: homeOptionsLoading } =
    useQuery(GET_HOME_OPTIONS)
  const { data: building_types } = useQuery(GET_BUILDING_TYPES)

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins']
      }
    })
  }, [])

  const setContents = (name, content) => {
    formik.setFieldValue(name, content)
  }

  const INITIAL_VALUES = {
    policy_options: [],
    inception_date: '',
    interval: '',
    expiry_date: '',
    physical_address: '',
    digital_address: '',
    have_insured_building_before: '',
    prev_building_insurance: '',
    building_type: '',
    building_color: '',
    construction_year: '',
    is_residential: '',
    is_for_business: '',
    has_financial_institution_interest: '',
    has_financial_interest: '',
    has_buy_back: '',
    building_materials: [],
    roofing_materials: [],
    insure_walls: '',
    wall_rebuilding_cost: '',
    rebuidling_cost: '',
    have_insured_content_before: '',
    prev_content_insurance: '',
    content_items: '',
    content_sum_assured: '',
    content_people: '',
    have_been_personally_insured: '',
    prev_personal_accident_policy: '',
    personal_accident_sum_assured: '',
    have_been_public_liability_insured: '',
    prev_public_liability_policy: '',
    public_liability_sum_assured: '',
    have_been_rent_insured: '',
    prev_rent_policy: '',
    rent_sum_assured: '',
    purchase_for_self: '',
    beneficiary_name: '',
    beneficiary_email: '',
    beneficiary_phone_number: '',
    beneficiary_id: ''
  }

  const [initialValues, handleUpdateForm] = useLocalStorageState({
    key: LOCAL_STORAGE_KEY,
    value: INITIAL_VALUES
  })

  const formik = useFormik({
    initialValues: INITIAL_VALUES,

    validationSchema: Yup.object({
      inception_date: Yup.date()
        .min(
          currentDate,
          'Policy Start date must be equal or greater then current date '
        )
        .required('Kindly indicate when you want your insurance to start'),

      interval: Yup.string().required(
        'Kindly indicate how long you want your insurance to last'
      ),

      physical_address: Yup.string().required(
        'Kindly provide your physical address'
      ),

      digital_address: Yup.string()
        .required('Kindly provide your digital address')
        .matches(
          /^[A-Z]{2}-[0-9]{3,4}-[0-9]{4}$/,
          'Please enter a valid digital address'
        ),
      have_insured_building_before: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Building')
            .length > 0,
        then: Yup.string().required(
          'Kindly indicate if you have insured your building before'
        )
      }),

      prev_building_insurance: Yup.string().when(
        'have_insured_building_before',
        {
          is: have_insured_building_before =>
            have_insured_building_before === 'Yes',
          then: Yup.string().required(
            'Kindly indicate your previous building insurance'
          )
        }
      ),

      building_type: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Building')
            .length > 0,
        then: Yup.string().required(
          'Kindly select the type of building you want to insure'
        )
      }),

      building_color: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Building')
            .length > 0,
        then: Yup.string().required(
          'Kindly indicated the color of the building'
        )
      }),

      construction_year: Yup.number().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Building')
            .length > 0,
        then: Yup.number().required(
          'Kindly select the year the building was completed'
        )
      }),

      is_residential: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Building')
            .length > 0,
        then: Yup.string().required(
          'Kindly indicate whether your building is residential or not'
        )
      }),

      is_for_business: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Building')
            .length > 0,
        then: Yup.string().required(
          'Kindly indicate whether your building is for business or not'
        )
      }),

      has_financial_institution_interest: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Building')
            .length > 0,
        then: Yup.string().required(
          'Kindly indicate whether your building has the interest of a financial institution or not'
        )
      }),

      has_financial_interest: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Building')
            .length > 0,
        then: Yup.string().required(
          'Kindly indicate whether your have financial interest in your building or not'
        )
      }),

      has_buy_back: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Building')
            .length > 0,
        then: Yup.string().required(
          'Kindly indicate whether you want buy back excess on your policy or not'
        )
      }),

      insure_walls: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Building')
            .length > 0,
        then: Yup.string().required(
          'Kindly indicate whether you want to insure your walls'
        )
      }),

      wall_rebuilding_cost: Yup.string().when('insure_walls', {
        is: insure_walls => insure_walls === 'Yes',
        then: Yup.string().required(
          'Kindly indicate your previous building insurance'
        )
      }),

      rebuidling_cost: Yup.number().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Building')
            .length > 0,
        then: Yup.number().required(
          'Kindly indicate whether you want to insure your walls'
        )
      }),

      have_insured_content_before: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Content')
            .length > 0,
        then: Yup.string().required(
          'Kindly indicate if you have insured your content before'
        )
      }),

      prev_content_insurance: Yup.string().when('have_insured_content_before', {
        is: have_insured_content_before =>
          have_insured_content_before === 'Yes',
        then: Yup.string().required(
          'Kindly indicate your previous content insurance'
        )
      }),

      content_sum_assured: Yup.number().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Content')
            .length > 0,
        then: Yup.number().required(
          'Kindly indicate your preferred content sum insured'
        )
      }),

      have_been_personally_insured: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(
            e => e.insurance_product_id === 'Personal Accident'
          ).length > 0,
        then: Yup.string().required(
          'Kindly indicate if you have had Personal Accident insurance'
        )
      }),

      prev_personal_accident_policy: Yup.string().when(
        'have_been_personally_insured',
        {
          is: have_been_personally_insured =>
            have_been_personally_insured === 'Yes',
          then: Yup.string().required(
            'Kindly indicate your previous Personal Accident insurance'
          )
        }
      ),

      personal_accident_sum_assured: Yup.number().when('policy_options', {
        is: policy_options =>
          policy_options.filter(
            e => e.insurance_product_id === 'Personal Accident'
          ).length > 0,
        then: Yup.number().required(
          'Kindly indicate how much you want for Personal Accident sum assured'
        )
      }),

      have_been_public_liability_insured: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(
            e => e.insurance_product_id === 'Public Liability'
          ).length > 0,
        then: Yup.string().required(
          'Kindly indicate if you have had Public Liability insurance'
        )
      }),

      prev_public_liability_policy: Yup.string().when(
        'have_been_public_liability_insured',
        {
          is: have_been_public_liability_insured =>
            have_been_public_liability_insured === 'Yes',
          then: Yup.string().required(
            'Kindly indicate your previous Public Liability insurance'
          )
        }
      ),

      public_liability_sum_assured: Yup.number().when('policy_options', {
        is: policy_options =>
          policy_options.filter(
            e => e.insurance_product_id === 'Public Liability'
          ).length > 0,
        then: Yup.number().required(
          'Kindly indicate how much you want for Public Liability sum assured'
        )
      }),

      have_been_rent_insured: Yup.string().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Rent').length >
          0,
        then: Yup.string().required(
          'Kindly indicate if you have had Rent insurance'
        )
      }),

      prev_rent_policy: Yup.string().when('have_been_rent_insured', {
        is: have_been_rent_insured => have_been_rent_insured === 'Yes',
        then: Yup.string().required(
          'Kindly indicate your previous Rent insurance'
        )
      }),

      rent_sum_assured: Yup.number().when('policy_options', {
        is: policy_options =>
          policy_options.filter(e => e.insurance_product_id === 'Rent').length >
          0,
        then: Yup.number().required(
          'Kindly indicate how much you want for Rent sum assured'
        )
      }),

      purchase_for_self: Yup.string().required(
        'Kindly indicate whether you are requesting for yourself or not'
      ),

      beneficiary_name: Yup.string().when('purchase_for_self', {
        is: purchase_for_self => purchase_for_self === 'No',
        then: Yup.string().required(
          'Kindly indicate the name of the person you are purchasing for'
        )
      }),

      beneficiary_email: Yup.string()
        .email()
        .when('purchase_for_self', {
          is: purchase_for_self => purchase_for_self === 'No',
          then: Yup.string().email('Kindly enter a valid email address')
        }),

      beneficiary_phone_number: Yup.string().when('purchase_for_self', {
        is: purchase_for_self => purchase_for_self === 'No',
        then: Yup.string()
          .max(10, 'Must be 10 numbers or less')
          .matches(
            /[0-9]{3}[0-9]{3}[0-9]{4}/,
            'Must contain only digit characters'
          )
          .required(
            'Kindly provide the phone number of the beneficiary of the cover'
          )
      }),
      beneficiary_id: Yup.string().when('purchase_for_self', {
        is: purchase_for_self => purchase_for_self === 'No',
        then: Yup.string()
          .required(
            'ID number is required of beneficiary or company is required'
          )
          // .matches(/^[A-Z]{3}-[0-9]{9}-[0-9]{1}$/, 'Invalid ID number')
      })
    }),

    onSubmit: values => {
      const updatedProgress = [...progress]
      updatedProgress[currentFormIndex] = 100
      setProgress(updatedProgress)
      // console.log(values)
      confirmSubmission()
    }
  })

  const [
    SubmitHomeOwnerPolicyRequest,
    { homeOwnerPolicyRequestError, homeOwnerPolicyRequestLoading }
  ] = useMutation(SUBMIT_REGISTERED_CUSTOMER_REQUEST, {
    variables: {
      created_by: 'self',
      customer_id: user?.id,
      request_type_id: 2,
      status: 'pending',
      home_details: formik.values,
      motor_details: null
    },
    onCompleted: data => SuccessNotification('Successfully submitted request'),
    onError: error => console.log(error),
    refetchQueries: [
      { query: CUSTOMER_PIE_PLOT, variables: { customer_id: user?.id } },
      'getPiePlotData'
    ]
  })

  const confirmSubmission = () => {
    Swal.fire({
      title: 'Are you sure you want to submit this request?',
      text: "You won't be able to revert this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, accept',
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        SubmitHomeOwnerPolicyRequest()
        navigate('/customer')
      }
    })
  }

  const setInceptionDate = e => {
    const value = e.target.value
    formik.setFieldValue(
      'inception_date',
      new Date(value).toISOString().substring(0, 10)
    )
  }

  const onPeriodInput = e => {
    let initialdate = formik.values['inception_date']

    if (initialdate) {
      // formik.setFieldValue("expiry_date", addMonthV2(initialdate, interval));
      let finaldate = addDays(initialdate, parseInt(e.target.value))
      let formatteddate = formatDate(finaldate)
      formik.setFieldValue('expiry_date', finaldate)

      setPeriod(formatteddate)
    }

    // console.log(formik.values['inception_date'])
  }

  const increaseProgress = () => {
    if (mySteps[currentFormIndex]?.content.length > 1) {
      let section = 100 / (mySteps[currentFormIndex].content.length - 1)
      const updatedProgress = [...progress]
      updatedProgress[currentFormIndex] = section + progress[currentFormIndex]
      setProgress(updatedProgress)
    }
    // else if (mySteps[currentFormIndex]?.content.length == 1) {
    //   const updatedProgress = [...progress]
    //   updatedProgress[currentFormIndex] = 100
    //   setProgress(updatedProgress)
    // }
  }

  const reduceProgress = () => {
    if (questionSetIndex < mySteps[currentFormIndex]?.content.length) {
      let section = 100 / (mySteps[currentFormIndex]?.content.length - 1)
      const updatedProgress = [...progress]
      updatedProgress[currentFormIndex] = progress[currentFormIndex] - section
      setProgress(updatedProgress)
    }
    // else if (mySteps[currentFormIndex]?.content.length == 1) {
    //   const updatedProgress = [...progress]
    //   updatedProgress[currentFormIndex] = 0
    //   setProgress(updatedProgress)
    // }
  }

  const setOptions = name => {
    if (name === 'construction_year') {
      const year = new Date().getFullYear()
      const yearsBefore = Array.from(
        new Array(60),
        (val, index) => year - index
      )
      let years = []
      yearsBefore.map(year => {
        let obj = {
          id: year,
          name: year
        }
        years.push(obj)
      })

      return years
    } else if (name === 'interval') {
      return months
    } else if (name === 'building_type') {
      return building_types.references
    } else {
      let options = [
        {
          name: 'Yes',
          id: 'Yes',
          value: true
        },
        {
          name: 'No',
          id: 'No',
          value: false
        }
      ]
      return options
    }
  }

  const stepValidator = () => {
    let validatated = true
    // console.log("I'm invoked")

    for (let i = 0; i < currentSectionArray.length; i++) {
      if (
        currentSectionArray[i] === 'building_materials' ||
        currentSectionArray[i] === 'roofing_materials' ||
        currentSectionArray[i] === 'content_items' ||
        currentSectionArray[i] === 'content_people'
      ) {
        if (formik.values[currentSectionArray[i]].length == 0) {
          validatated = validatated && false
        }
      } else if (formik.touched[currentSectionArray[i]] != true) {
        validatated = validatated && false
        // console.log(currentSectionArray[i] + " Was not touched")
      } else if (formik.errors[currentSectionArray[i]]) {
        validatated = validatated && false
        // console.log(currentSectionArray[i] + " has errors")
      } else {
        validatated = validatated && true
        // console.log(currentSectionArray[i] + " is valid")
      }
    }

    if (validatated == false)
      ErrorNotification('Kindly provide all the required information')

    return validatated
  }

  const nextStep = e => {
    e.preventDefault()

    if (stepValidator()) {
      if (questionSetIndex == mySteps[currentFormIndex]?.content.length - 1) {
        setCurrentFormIndex(currentFormIndex + 1)
        setquestionSetIndex(0)
        // console.log(formik.values)
      } else if (
        currentFormIndex == mySteps.length - 1 &&
        questionSetIndex == lastSet?.content.length - 1
      ) {
        increaseProgress()
        completeForm()
        // console.log(formik.values)
      } else {
        setquestionSetIndex(questionSetIndex + 1)
        increaseProgress()
        // console.log(formik.values)
      }
    }
  }

  const prevStep = e => {
    e.preventDefault()
    if (questionSetIndex > 0) {
      setquestionSetIndex(questionSetIndex - 1)
      reduceProgress()
    } else if (questionSetIndex == 0 && currentFormIndex > 0) {
      setCurrentFormIndex(currentFormIndex - 1)
      setquestionSetIndex(mySteps[currentFormIndex].content.length - 1)
      reduceProgress()
    } else if (questionSetIndex > 0 && currentFormIndex == 0) {
      setquestionSetIndex(questionSetIndex - 1)
      reduceProgress()
    }
  }

  const completeForm = () => {
    increaseProgress()
  }

  const navigateToForm = formIndex => {
    // //Setting the progress of the current form to 0
    // let updatedProgress = [...progress];
    // updatedProgress[currentFormIndex] = 0;
    // updatedProgress[formIndex] = 0
    // setProgress(updatedProgress);

    //Navigating to the selected Form
    setCurrentFormIndex(formIndex) //Setting the selected form index
    setquestionSetIndex(0)
  }

  const makeActive = (currentFormIndex, name) => {
    if (mySteps[currentFormIndex].name === name) return 'active-class'
  }

  const iconSelect = (name, index) => {
    if (name === 'Policy Information')
      return (
        <AiOutlineBook
          onClick={() => navigateToForm(index)}
          className={makeActive(currentFormIndex, name)}
          size={20}
          style={{ marginRight: 25 }}
        />
      )
    else if (name === 'Home Details')
      return (
        <AiOutlineHome
          onClick={() => navigateToForm(index)}
          className={makeActive(currentFormIndex, name)}
          size={20}
          style={{ marginRight: 25 }}
        />
      )
    else if (name === 'Policy Holder Information')
      return (
        <BiUserPlus
          onClick={() => navigateToForm(index)}
          className={makeActive(currentFormIndex, name)}
          size={20}
          style={{ marginRight: 25 }}
        />
      )
    else if (name === 'Content Insurance')
      return (
        <BiArchive
          onClick={() => navigateToForm(index)}
          className={makeActive(currentFormIndex, name)}
          size={20}
          style={{ marginRight: 25 }}
        />
      )
    else if (name === 'Personal Accident')
      return (
        <GiHazardSign
          onClick={() => navigateToForm(index)}
          className={makeActive(currentFormIndex, name)}
          size={20}
          style={{ marginRight: 25 }}
        />
      )
    else if (name === 'Rent Insurance')
      return (
        <BiBuildingHouse
          onClick={() => navigateToForm(index)}
          className={makeActive(currentFormIndex, name)}
          size={20}
          style={{ marginRight: 25 }}
        />
      )
    else if (name === 'Personal Liability')
      return (
        <GiTakeMyMoney
          onClick={() => navigateToForm(index)}
          className={makeActive(currentFormIndex, name)}
          size={20}
          style={{ marginRight: 25 }}
        />
      )
    else if (name === 'Building Insurance')
      return (
        <BsBuilding
          onClick={() => navigateToForm(index)}
          className={makeActive(currentFormIndex, name)}
          size={20}
          style={{ marginRight: 25 }}
        />
      )
  }

  return (
    <>
      {homeOptionsLoading ? (
        <div>
          <img
            style={{
              position: 'relative',
              marginTop: isMobile ? '80%' : '20%',
              left: isMobile ? '35%' : '45%'
            }}
            src={customLoading}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <header
              id='header'
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch'
              }}
              className='fixed-top'
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  width: '100%',
                  padding: isMobile ? 0 : 5
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '75%'
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      margin: isMobile ? 5 : 15,
                      left: isMobile ? -35 : 0
                    }}
                  >
                    <a href='/'>
                      <img
                        style={{
                          height: isMobile ? '18px' : '28px',
                          width: isMobile ? '129px' : '259.84px'
                        }}
                        src={myPolicyLogo}
                      />
                    </a>
                  </div>
                  <div>
                    {isMobile ? (
                      <>
                        <button
                          className='btn btn-primary'
                          onClick={e => {
                            e.preventDefault()
                           navigate(-1)
                          }}
                          style={{
                            backgroundColor: '#FFFFFF',
                            border: 'none',
                            fontWeight: 'bold'
                          }}
                        >
                          <AiOutlineArrowLeft
                            size={20}
                            style={{ color: '#2E79E9' }}
                          />
                        </button>
                      </>
                    ) : (
                      <input
                        className='getstarted font-loader scrollto'
                        onClick={e => {
                          e.preventDefault()
                          navigate(-1)
                        }}
                        style={{
                          color: '#F6F8FB',
                          backgroundColor: '#2E79E9',
                          border: 'none',
                          width: '160px',
                          height: '40px',
                          fontSize: '18px'
                        }}
                        type='button'
                        value='Go Back'
                      />
                    )}
                  </div>
                </div>
              </div>
            </header>
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: isMobile ? 80 : 200,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {submit ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {isMobile ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    {mySteps && mySteps[currentFormIndex] ? (
                      <div style={{ marginTop: 25 }}>
                        <div>
                          <div className='icon'>
                            {iconSelect(
                              mySteps[currentFormIndex].name,
                              currentFormIndex
                            )}
                          </div>
                          <div>
                            <LinearProgress
                              className='progress-mobile'
                              variant='determinate'
                              value={progress[currentFormIndex]}
                            />
                          </div>
                          <div style={{ textAlign: 'center' }}>
                            <span
                              className='progress-value font-loader'
                              style={{
                                display: 'block'
                              }}
                            >
                              Step {questionSetIndex + 1}/
                              {mySteps[currentFormIndex]?.content.length}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {mySteps &&
                      mySteps?.map((step, index) => {
                        return (
                          <div style={{ textAlign: 'center' }}>
                            <div style={{ cursor: 'pointer' }} className='icon'>
                              {iconSelect(step.name, index)}
                            </div>
                            <span
                              style={{
                                color:
                                  index == currentFormIndex
                                    ? 'rgb(46, 121, 233)'
                                    : null
                              }}
                              className='font-loader '
                            >
                              {step.name}
                            </span>
                            <div>
                              <LinearProgress
                                className='progress'
                                variant='determinate'
                                value={progress[index]}
                              />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span
                                className='progress-value font-loader'
                                style={{
                                  display:
                                    currentFormIndex != index ? 'none' : 'block'
                                }}
                              >
                                Step {questionSetIndex + 1}/
                                {mySteps[currentFormIndex]?.content.length}
                              </span>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <h3
                    className='font-loader'
                    style={{ textAlign: 'center', marginTop: 20 }}
                  >
                    {mySteps[currentFormIndex]?.content[questionSetIndex].title}
                  </h3>
                  <div className='form-container'>
                    <Form>
                      {mySteps[currentFormIndex]?.content[
                        questionSetIndex
                      ].questions.map(current => {
                        currentSectionArray.push(current.name)

                        // console.log(currentSectionArray)

                        return (
                          <>
                            {current.type === 'select' ? (
                              <FormOptionsV2
                                type={current.type}
                                name={current.name}
                                label={current.label}
                                handleChange={e => {
                                  e.preventDefault()
                                  if (current.name === 'interval') {
                                    formik.handleChange(e)
                                    onPeriodInput(e)
                                  } else {
                                    formik.handleChange(e)
                                  }
                                }}
                                options={setOptions(current.name)}
                                value={formik.values[current.name]}
                                style={{
                                  borderColor:
                                    formik.touched[current.name] &&
                                    formik.errors[current.name]
                                      ? 'red'
                                      : null
                                }}
                                onBlur={formik.handleBlur}
                              />
                            ) : current.type === 'array' &&
                              current.name === 'content_items' ? (
                              <AddDeleteTableRows
                                name={current.name}
                                label={current.label}
                                setContents={setContents}
                              />
                            ) : current.type === 'array' &&
                              current.name === 'content_people' ? (
                              <AddDeleteTablePeopleRows
                                name={current.name}
                                label={current.label}
                                setContents={setContents}
                              />
                            ) : current.type === 'check' ? (
                              <MaterialsPicker
                                name={current.name}
                                label={current.label}
                                materials={formik.values[current.name]}
                                handleChange={formik.handleChange}
                              />
                            ) : (
                              <FormInput
                                type={current.type}
                                placeholder={current?.placeholder}
                                name={current.name}
                                label={current.label}
                                handleChange={
                                  current.name == 'inception_date'
                                    ? setInceptionDate
                                    : formik.handleChange
                                }
                                value={formik.values[current.name]}
                                style={{
                                  borderColor:
                                    formik.touched[current.name] &&
                                    formik.errors[current.name]
                                      ? 'red'
                                      : null
                                }}
                                onBlur={formik.handleBlur}
                              />
                            )}
                            {formik.values['expiry_date'] &&
                            current.name === 'interval' ? (
                              <div
                                style={{
                                  marginBottom: 15,
                                  marginTop: -12
                                }}
                              >
                                <span
                                  style={{
                                    position: 'relative',
                                    fontSize: 13
                                  }}
                                >
                                  Your policy will expire on{' '}
                                  <span style={{ fontWeight: 'bold' }}>
                                    {period}
                                  </span>
                                </span>
                              </div>
                            ) : null}
                            {formik.touched[current.name] &&
                            formik.errors[current.name] ? (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: 12,
                                  marginTop: -8,
                                  marginBottom: 8
                                }}
                              >
                                {formik.errors[current.name]}
                              </p>
                            ) : null}
                          </>
                        )
                      })}
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          {questionSetIndex != 0 && (
                            <button
                              className='btn btn-primary font-loader'
                              style={{ width: '25%' }}
                              onClick={e => prevStep(e)}
                            >
                              Back
                            </button>
                          )}
                          {(currentFormIndex == mySteps.length - 1 &&
                            questionSetIndex == lastSet?.content.length - 1) ||
                          formik.values.purchase_for_self === 'Yes' ? (
                            <button
                              className='btn btn-primary font-loader'
                              style={{
                                width:
                                  questionSetIndex != 0 && currentFormIndex >= 0
                                    ? '65%'
                                    : '100%'
                              }}
                              // type='submit'
                              onClick={e => {
                                e.preventDefault()
                                formik.handleSubmit()
                              }}
                              disabled={
                                homeOwnerPolicyRequestLoading
                              }
                            >
                             {homeOwnerPolicyRequestLoading ? 'Submitting...' : 'Submit'}
                            </button>
                          ) : (
                            <button
                              className='btn btn-primary font-loader'
                              style={{
                                width:
                                  questionSetIndex != 0 && currentFormIndex >= 0
                                    ? '65%'
                                    : '100%'
                              }}
                              onClick={e => nextStep(e)}
                            >
                              Continue
                            </button>
                          )}
                        </div>
                      </>
                    </Form>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <ProductPicker
                  setSteps={setMySteps}
                  setSubmit={setSubmit}
                  products={formik.values.policy_options}
                  setLastSet={setLastSet}
                  setProgress={setProgress}
                  homeOptions={homeOptions}
                  homeOptionsLoading={homeOptionsLoading}
                />
              </div>
            )}
            {/* <AiOutlineCar className='active-class' size={20} style={{ marginRight: 25 }} />
             <LinearProgress style={{ width: 300 }} variant="determinate" value={progress} /> */}
          </div>
        </div>
      )}
    </>
  )
}

export default HomeOwnerFormLoggedIn

const ProductPicker = ({
  setSubmit,
  products,
  setSteps,
  setLastSet,
  setProgress,
  homeOptions,
  homeOptionsLoading
}) => {
  let steps = []
  let progress = [0]
  let finalSteps = [profileinfoLoggedIn]
  let finalStepsProgress = [0, 0]

  steps.push(PoilcyDetails)

  const addToSteps = name => {
    if (name === 'Building') {
      steps?.push(Building)
      progress.push(0)
    } else if (name === 'Content') {
      progress.push(0)
      steps?.push(Content)
    } else if (name === 'Rent') {
      progress.push(0)
      steps?.push(Rent)
    } else if (name === 'Personal Accident') {
      progress.push(0)
      steps?.push(Personal_Accident)
    } else if (name === 'Public Liability') {
      progress.push(0)
      steps?.push(Public_Liability)
    }
  }

  const handleChange = e => {
    const { value, checked, name } = e.target

    if (checked) {
      products?.push({ insurance_product_id: value })
      addToSteps(name)
    } else {
      products = products?.filter(product => {
        return product.insurance_product_id !== value
      })
      steps = steps.filter(step => {
        return step.name.includes(name) == false
      })
    }
  }

  const productOptions = homeOptions?.request_types[0]?.properties.products

  const next = () => {
    if (products.length == 0) {
      ErrorNotification('Kindly select a home owner product')
    } else {
      setSubmit(true)
      steps = [...steps, ...finalSteps]
      setSteps(steps)
      progress = [...progress, ...finalStepsProgress]
      setProgress(progress)
      setLastSet(steps[Math.abs(steps.length - 1)])
    }
  }

  return homeOptionsLoading ? null : (
    <div className='form-container'>
      <h3 className='font-loader' style={{ textAlign: 'center' }}>
        Home Owner Request Form
      </h3>
      <hr />
      <Form>
        <Form.Group>
          <Form.Label>
            <span className='font-loader' style={{ fontSize: 25 }}>
              What type of cover are you looking for?
            </span>
            <span style={{ color: 'red' }}>*</span>
          </Form.Label>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around'
            }}
          >
            {productOptions?.map((option, key) => {
              return (
                <Form.Check
                  key={key}
                  className='font-loader'
                  value={option.name}
                  label={option.name}
                  onChange={handleChange}
                  name={option.name}
                />
              )
            })}
          </div>
        </Form.Group>
        <button
          className='btn btn-primary font-loader'
          style={{ width: '100%' }}
          onClick={e => {
            e.preventDefault()
            next()
          }}
        >
          Next
        </button>
      </Form>
    </div>
  )
}
