import React, { useState, useEffect } from 'react';
import No_Quotes from './No_Quotes.svg';
import Swal from 'sweetalert2';
import './styles.css';
import './index.css';

import { Modal } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { formatCurrency } from '../../../utils/FormatCurrency';
import { formatDate, getToday } from '../../../utils/FormatDate';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  REJECT_QUOTE,
  ACCEPT_QUOTE,
  CANCEL_POLICY_REQUEST,
  UPDATE_REQUEST_COMMISSION
} from 'src/Graphql/Mutations';
import {
  GET_CUSTOMER_HOME_OWNER_REQUEST_DETAILS,
  GET_PROXY_INSURER_COMMISSION
} from 'src/Graphql/Queries';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  ErrorNotification,
  SuccessNotification
} from 'src/components/Notification';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const RequestDetails2 = () => {
  const [show, setShow] = useState(false);
  const [request, setRequest] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [acceptedQuoteID, setAcceptedQuoteID] = useState(null);
  const [acceptedProxyID, setAcceptedProxyID] = useState(null);
  const [acceptedInsurerID, setAcceptedInsurerID] = useState(null);
  const location = useLocation();
  const requestID = location.state;
  const navigate = useNavigate();
  const [prods, setProds] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const tabs = ['Policy Information', 'Cover Information'];

  const { data, loading: dataLoading } = useQuery(
    GET_CUSTOMER_HOME_OWNER_REQUEST_DETAILS,
    {
      variables: {
        requestID: requestID
      },

      onCompleted: data => {
        // console.log(data.requests[0])
        setRequest(data?.requests[0]);
        let mytabs = [];
        data?.requests[0]?.home_details.policy_options.map(option => {
          mytabs.push(option.insurance_product_id);
        });
        setProds(mytabs);
      }
    }
  );

  const months = [
    { month: 1, id: 30 },
    { month: 2, id: 60 },
    { month: 3, id: 90 },
    { month: 4, id: 120 },
    { month: 5, id: 150 },
    { month: 6, id: 180 },
    { month: 7, id: 210 },
    { month: 8, id: 240 },
    { month: 9, id: 270 },
    { month: 10, id: 300 },
    { month: 11, id: 330 },
    { month: 12, id: 365 },
    { month: 24, id: 730 },
    { month: 36, id: 1068 }
  ];

  const getDuration = days => {
    let duration = months.filter(index => index.id == Number(days));

    return duration[0]?.month;
  };

  const [acceptQuote] = useMutation(ACCEPT_QUOTE, {
    onCompleted: data => {
      SuccessNotification('Successfully accepted quote');
      navigate(-1);
    },

    onError: error => ErrorNotification(error)
  });

  const [cancelRequest] = useMutation(CANCEL_POLICY_REQUEST, {
    variables: {
      _requestID: requestID
    },
    onCompleted: data => {
      SuccessNotification('Successfully cancelled request');
      navigate(-1);
    },
    onError: error => {
      ErrorNotification('Cancellation of request failed');
      console.log(error);
    }
  });

  const [rejectQuoteMutation] = useMutation(REJECT_QUOTE, {
    onCompleted: data => SuccessNotification('Successfully rejected quote'),

    onError: error => ErrorNotification(error),

    refetchQueries: [
      {
        query: GET_CUSTOMER_HOME_OWNER_REQUEST_DETAILS,
        variables: { requestID: requestID }
      },
      'GetMotorRequestByRequestID'
    ]
  });

  const [updateRequestCommission] = useMutation(UPDATE_REQUEST_COMMISSION, {
    onCompleted: () => {
      submitrequest(acceptedQuoteID);
    }
  });

  const [getProxyInsurerCommission] = useLazyQuery(
    GET_PROXY_INSURER_COMMISSION,
    {
      onCompleted: data => {
        updateRequestCommission({
          variables: {
            requestID: requestID,
            commission: data?.proxy_commissions[0].broker_commission
          }
        });
      }
    }
  );

  useEffect(() => {
    if (acceptedInsurerID && acceptedProxyID) {
      getProxyInsurerCommission({
        variables: {
          insurerID: acceptedInsurerID,
          proxyID: acceptedProxyID
        }
      });
    }
  }, [acceptedInsurerID, acceptedProxyID]);

  const modal = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        animation={true}
        size="lg"
        centered
        backdrop="static"
        style={{ borderRadius: 25 }}
        className="font-loader"
      >
        <Modal.Header>
          <Modal.Title>
            <h1 className="modal-title">Quote Details</h1>
          </Modal.Title>
          <button
            style={{
              position: 'relative',
              right: 15,
              width: 10,
              height: 10
            }}
            onClick={() => handleClose()}
            type="button"
            className="close"
            aria-label="Close"
          >
            <XMarkIcon style={{ height: 20 }} />
          </button>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              paddingLeft: 25
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left'
              }}
            >
              <p className="quote-title-items"> Insurance Company:</p>
              <p
                className="quote-detail-items"
                style={{ position: 'relative', left: 50 }}
              >
                {selectedQuote?.insurance_company.name}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left'
              }}
            >
              <p className="quote-title-items"> Premium Amount:</p>
              <p
                className="quote-detail-items"
                style={{ position: 'relative', left: 70 }}
              >
                {formatCurrency(selectedQuote?.amount)}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left'
              }}
            >
              <p className="quote-title-items"> Brokered By:</p>
              <p
                className="quote-detail-items"
                style={{ position: 'relative', left: 120 }}
              >
                {selectedQuote?.proxy.name}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left'
              }}
            >
              <p className="quote-title-items"> Quoted on:</p>
              <p
                className="quote-detail-items"
                style={{ position: 'relative', left: 130 }}
              >
                {formatDate(selectedQuote?.created_at)}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left'
              }}
            >
              <p className="quote-title-items"> Additional Benefits:</p>
              <p
                className="quote-detail-items"
                style={{ position: 'relative', left: 150 }}
              >
                {selectedQuote?.additional_options}
              </p>
            </div>
          </div>
          {selectedQuote?.status === 'rejected' ? null : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <button
                onClick={e => {
                  e.preventDefault();
                  rejectQuote(selectedQuote?.id);
                }}
                className="cancel-btn-modal"
                style={{ width: '48%' }}
              >
                <XMarkIcon style={{ height: 20, marginRight: 5 }} />
                Decline Quote
              </button>
              <button
                onClick={e => {
                  // submitrequest(selectedQuote?.id)
                  e.preventDefault();
                  setAcceptedQuoteID(selectedQuote?.id);
                  setAcceptedInsurerID(selectedQuote?.insurance_company.id);
                  setAcceptedProxyID(selectedQuote?.proxy.id);
                }}
                className="accept-btn-modal"
                style={{ width: '48%' }}
              >
                <CheckIcon style={{ height: 20, marginRight: 5 }} />
                Accept Quote
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  const submitrequest = id => {
    setShow(false);
    Swal.fire({
      title: 'Are you sure you want to accept this offer?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes,accept',
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        acceptQuote({
          variables: {
            quoteID: id,
            requestID: requestID,
            requestStatus: 'active',
            quoteStatus: 'accepted'
          }
        });
      }
    });
  };

  const rejectQuote = id => {
    setShow(false);
    Swal.fire({
      title: 'Are you sure you want to reject this offer?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes,decline',
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        rejectQuoteMutation({
          variables: {
            quoteID: id,
            quoteStatus: 'rejected'
          }
        });
      }
    });
  };

  const cancel = () => {
    // setShow(false)
    Swal.fire({
      title: 'Are you sure you want to Cancel this request?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes,cancel',
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        cancelRequest();
      }
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      {dataLoading ? (
        <Spinner
          animation="border"
          style={{ position: 'relative', marginTop: '20%', left: '45%' }}
        />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {modal()}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: 20,
              paddingRight: 20,
              background: '#F8F8FA',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '2rem',
                marginTop: 25
              }}
            >
              {tabs.map((tab, key) => (
                <button
                  key={key}
                  onClick={e => {
                    e.preventDefault();
                    setActiveTab(key);
                  }}
                  className={activeTab === key ? 'active-tab' : 'tab-button'}
                >
                  {tab}
                </button>
              ))}
              {data?.requests[0]?.home_details?.purchase_for_self === 'No' ? (
                <button
                  onClick={e => {
                    e.preventDefault();
                    setActiveTab(2);
                  }}
                  className={activeTab == 2 ? 'active-tab' : 'tab-button'}
                >
                  Policy Holder Information
                </button>
              ) : null}
              <span style={{ fontSize: 50, fontWeight: 100 }}>|</span>
              {data?.requests[0]?.quotes?.length > 0 ? (
                <button
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={e => {
                    e.preventDefault();
                    setActiveTab('quotes');
                  }}
                  className={
                    activeTab === 'quotes' ? 'active-tab' : 'tab-button'
                  }
                >
                  Quotes{' '}
                  <Badge className="badgeStyle">
                    {data?.requests[0]?.quotes?.length}
                  </Badge>
                </button>
              ) : (
                <button
                  onClick={e => {
                    e.preventDefault();
                    cancel();
                  }}
                  className="cancel-btn"
                >
                  <XMarkIcon style={{ height: 20, marginRight: 5 }} />
                  Cancel{' '}
                </button>
              )}
            </div>
            <div>
              <Badge
                className={
                  data?.requests[0]?.quotes?.length > 0
                    ? 'answered-request'
                    : 'unanswered-request'
                }
              >
                {data?.requests[0]?.quotes?.length > 0
                  ? 'Answered'
                  : 'Unanswered'}
              </Badge>
            </div>
          </div>
          <div style={{ paddingLeft: 25, paddingRight: 25 }}>
            {activeTab == 0 ? (
              <>
                <TableContainer>
                  <Table>
                    <TableHead className="table-header">
                      <TableRow>
                        <TableCell className="title-side" id="columHead">
                          Title
                        </TableCell>
                        <TableCell className="detail-side" id="columHead">
                          Details
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell id="titleSide">
                          Preferred Policy Cover
                        </TableCell>
                        <TableCell id="detailSide">
                          <ul style={{ marginLeft: 25 }}>
                            {data?.requests[0]?.home_details.policy_options.map(
                              (option, key) => (
                                <li key={key}>{option.insurance_product_id}</li>
                              )
                            )}
                          </ul>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell id="titleSide">Policy Start Date</TableCell>
                        <TableCell id="detailSide">
                          {formatDate(
                            data?.requests[0]?.home_details?.inception_date
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell id="titleSide">Policy Duration</TableCell>
                        <TableCell id="detailSide">
                          {getDuration(
                            data?.requests[0]?.home_details?.interval
                          )}{' '}
                          month(s)
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell id="titleSide">Digital Address</TableCell>
                        <TableCell id="detailSide">
                          {data?.requests[0]?.home_details?.digital_address}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell id="titleSide">Digital Address</TableCell>
                        <TableCell id="detailSide">
                          {data?.requests[0]?.home_details?.physical_address}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : activeTab === 1 ? (
              <>
                <div>
                  {prods
                    ? prods.map((prod, key) => (
                        <>
                          <Accordion
                            expanded={true}
                            style={{
                              borderRadius: 8,
                              marginBottom: 10,
                              fontFamily: 'Poppins'
                            }}
                            key={key}
                          >
                            <AccordionSummary
                              aria-controls="panel1bh-content"
                              id="panel1a-header"
                              style={{
                                background: '#EAF2FD',
                                borderRadius: '8px 8px 0px 0px',
                                color: '#2055A3',
                                border: 'solid #97BCF4 1px'
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: 500,
                                  fontFamily: 'Poppins'
                                }}
                              >
                                {prod}&nbsp;Information
                              </Typography>
                            </AccordionSummary>
                            {prod === 'Building' ? (
                              <AccordionDetails
                                style={{
                                  border: 'solid #97BCF4 1px',
                                  borderRadius: '0px 0px 8px 8px'
                                }}
                              >
                                <Typography>
                                  <Table>
                                    <TableHead className="table-header">
                                      <TableRow>
                                        <TableCell
                                          className="title-side"
                                          id="columHead"
                                        >
                                          Title
                                        </TableCell>
                                        <TableCell
                                          className="detail-side"
                                          id="columHead"
                                        >
                                          Details
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Previous Building Insurance
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          {
                                            data?.requests[0]?.home_details
                                              ?.prev_building_insurance
                                          }
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Type of Building
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          {
                                            data?.requests[0]?.home_details
                                              ?.building_type
                                          }
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Building Color
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          {
                                            data?.requests[0]?.home_details
                                              ?.building_color
                                          }
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Construction Year of Building
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          {
                                            data?.requests[0]?.home_details
                                              ?.construction_year
                                          }
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Is the building residential?
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          {
                                            data?.requests[0]?.home_details
                                              ?.is_residential
                                          }
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Is business conducted in the building?
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          {
                                            data?.requests[0]?.home_details
                                              ?.is_for_business
                                          }
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Does the building have interest from
                                          any financial institution?
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          {
                                            data?.requests[0]?.home_details
                                              ?.has_financial_institution_interest
                                          }
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Do you have any financial interest in
                                          the building?
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          {
                                            data?.requests[0]?.home_details
                                              ?.has_financial_interest
                                          }
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Materials used for building
                                          Construction
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          <ul
                                            class="list-group"
                                            style={{
                                              marginTop: 10,
                                              marginLeft: 25
                                            }}
                                          >
                                            {data?.requests[0]?.home_details?.building_materials?.map(
                                              (material, key) => {
                                                return (
                                                  <li key={key}>{material}</li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Materials used for roof Construction
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          <ul
                                            style={{
                                              marginTop: 10,
                                              marginLeft: 25
                                            }}
                                          >
                                            {data?.requests[0]?.home_details?.roofing_materials?.map(
                                              (material, key) => {
                                                return (
                                                  <li key={key}>{material}</li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Do you want to insure your walls?
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          {
                                            data?.requests[0]?.home_details
                                              ?.insure_walls
                                          }
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Value of wall
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          {formatCurrency(
                                            data?.requests[0]?.home_details
                                              ?.wall_rebuilding_cost
                                          )}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell id="titleSide">
                                          Value of building
                                        </TableCell>
                                        <TableCell id="detailSide">
                                          {formatCurrency(
                                            data?.requests[0]?.home_details
                                              ?.rebuidling_cost
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Typography>
                              </AccordionDetails>
                            ) : prod === 'Rent' ? (
                              <AccordionDetails
                                style={{
                                  border: 'solid #97BCF4 1px',
                                  borderRadius: '0px 0px 8px 8px'
                                }}
                              >
                                <Typography>
                                  <TableContainer>
                                    <Table>
                                      <TableHead className="table-header">
                                        <TableRow>
                                          <TableCell
                                            id="columHead"
                                            className="title-side"
                                          >
                                            Title
                                          </TableCell>
                                          <TableCell
                                            id="columHead"
                                            className="detail-side"
                                          >
                                            Details
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Has Previous Rent Insurance
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {
                                              data?.requests[0]?.home_details
                                                ?.have_been_rent_insured
                                            }
                                          </TableCell>
                                        </TableRow>

                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Previous Rent Policy
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {
                                              data?.requests[0]?.home_details
                                                ?.prev_rent_policy
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Preferred Rent Assured Amount
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {formatCurrency(
                                              data?.requests[0]?.home_details
                                                ?.rent_sum_assured
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Typography>
                              </AccordionDetails>
                            ) : prod === 'Public Liability' ? (
                              <AccordionDetails
                                style={{
                                  border: 'solid #97BCF4 1px',
                                  borderRadius: '0px 0px 8px 8px'
                                }}
                              >
                                <Typography>
                                  <TableContainer>
                                    {' '}
                                    <Table>
                                      <TableHead className="table-header">
                                        <TableRow>
                                          <TableCell
                                            id="columHead"
                                            className="title-side"
                                          >
                                            Title
                                          </TableCell>
                                          <TableCell
                                            id="columHead"
                                            className="detail-side"
                                          >
                                            Details
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <tbody>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Has Previously insured for Public
                                            Liability?
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {
                                              data?.requests[0]?.home_details
                                                ?.have_been_public_liability_insured
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Previous Public Liability Insurance
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {
                                              data?.requests[0]?.home_details
                                                ?.prev_public_liability_policy
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Preferred Public Liability assured
                                            Amount
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {formatCurrency(
                                              data?.requests[0]?.home_details
                                                ?.public_liability_sum_assured
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      </tbody>
                                    </Table>
                                  </TableContainer>
                                </Typography>
                              </AccordionDetails>
                            ) : prod === 'Personal Accident' ? (
                              <AccordionDetails
                                style={{
                                  border: 'solid #97BCF4 1px',
                                  borderRadius: '0px 0px 8px 8px'
                                }}
                              >
                                <Typography>
                                  <TableContainer>
                                    <Table>
                                      <TableHead className="table-header">
                                        <TableRow>
                                          <TableCell
                                            id="columHead"
                                            className="title-side"
                                          >
                                            Title
                                          </TableCell>
                                          <TableCell
                                            id="columHead"
                                            className="detail-side"
                                          >
                                            Details
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <tbody>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Has Previously insured for Personal
                                            Accident?
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {
                                              data?.requests[0]?.home_details
                                                ?.have_been_personally_insured
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Previous Personal Accident Insurance
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {
                                              data?.requests[0]?.home_details
                                                ?.prev_personal_accident_policy
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Preferred Personal Accident assured
                                            Amount
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {formatCurrency(
                                              data?.requests[0]?.home_details
                                                ?.personal_accident_sum_assured
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      </tbody>
                                    </Table>
                                  </TableContainer>
                                </Typography>
                              </AccordionDetails>
                            ) : (
                              <AccordionDetails
                                style={{
                                  border: 'solid #97BCF4 1px',
                                  borderRadius: '0px 0px 8px 8px'
                                }}
                              >
                                <Typography>
                                  <TableContainer>
                                    <Table>
                                      <TableHead className="table-header">
                                        <TableRow>
                                          <TableCell
                                            id="columHead"
                                            className="title-side"
                                          >
                                            Title
                                          </TableCell>
                                          <TableCell
                                            id="columHead"
                                            className="detail-side"
                                          >
                                            Details
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            {' '}
                                            Has Insured content before:
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {
                                              data?.requests[0]?.home_details
                                                .have_insured_content_before
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Previous Content Insurance
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {
                                              data?.requests[0]?.home_details
                                                .prev_content_insurance
                                            }
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Preferred Content Assured Amount
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {formatCurrency(
                                              data?.requests[0]?.home_details
                                                ?.content_sum_assured
                                            )}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Persons you want to insure
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {data?.requests[0]?.home_details?.content_people?.map(
                                              (val, key) => {
                                                return (
                                                  <div
                                                    className="content-item-container"
                                                    key={key}
                                                  >
                                                    <span className="content-item-title">
                                                      {val.name}
                                                    </span>
                                                    <span className="content-item">
                                                      Date&nbsp;Of&nbsp;Birth:
                                                      {formatDate(
                                                        val.date_of_birth
                                                      )}
                                                    </span>
                                                    <span className="content-item">
                                                      Occupation:&nbsp;
                                                      {val.occupation}
                                                    </span>
                                                    <span className="content-item">
                                                      Preferred&nbsp;Sum&nbsp;Assured:&nbsp;
                                                      {formatCurrency(
                                                        val.sum_assured
                                                      )}
                                                    </span>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell id="titleSide">
                                            Content you want to insure
                                          </TableCell>
                                          <TableCell id="detailSide">
                                            {data?.requests[0]?.home_details?.content_items?.map(
                                              (val, key) => {
                                                return (
                                                  <div
                                                    className="content-item-container"
                                                    key={key}
                                                  >
                                                    <span className="content-item-title">
                                                      {val.name}
                                                    </span>
                                                    <span className="content-item">
                                                      Quantity:&nbsp;
                                                      {val.quantity}
                                                    </span>
                                                    <span className="content-item">
                                                      Value:&nbsp;
                                                      {formatCurrency(
                                                        val.value
                                                      )}
                                                    </span>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Typography>
                              </AccordionDetails>
                            )}
                          </Accordion>
                          ;
                        </>
                      ))
                    : null}
                </div>
              </>
            ) : activeTab === 2 ? (
              <>
                <Table>
                  <TableHead className="table-header">
                    <TableRow>
                      <TableCell id="columHead" className="title-side">
                        Title
                      </TableCell>
                      <TableCell id="columHead" className="detail-side">
                        Details
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableRow>
                    <TableCell id="titleSide">Beneficiary name</TableCell>
                    <TableCell id="detailSide">
                      {data?.requests[0]?.home_details?.beneficiary_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="titleSide">
                      Beneficiary phone number
                    </TableCell>
                    <TableCell id="detailSide">
                      {request?.home_details.beneficiary_phone_number}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="titleSide">Beneficiary email</TableCell>
                    <TableCell id="detailSide">
                      {data?.requests[0]?.home_details?.beneficiary_email}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell id="titleSide">Beneficiary Id number</TableCell>
                    <TableCell id="detailSide">
                      {data?.requests[0]?.home_details?.beneficiary_id}
                    </TableCell>
                  </TableRow>
                </Table>
              </>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead className="table-header">
                    <TableRow>
                      <TableCell id="columHead">Date</TableCell>
                      <TableCell id="columHead">Broker</TableCell>
                      <TableCell id="columHead">Amount</TableCell>
                      <TableCell id="columHead">Insurance Company</TableCell>
                      <TableCell id="columHead">Additional Benefits</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  {data?.requests[0]?.quotes?.map((quote, key) => (
                    <TableRow key={key}>
                      <TableCell id="detailSide">
                        {formatDate(quote?.created_at)}
                      </TableCell>
                      <TableCell id="detailSide">{quote.proxy.name}</TableCell>
                      <TableCell id="detailSide">
                        {formatCurrency(quote.amount)}
                      </TableCell>
                      <TableCell id="detailSide">
                        {quote.insurance_company.name}
                      </TableCell>
                      <TableCell id="detailSide" style={{ paddingLeft: 25 }}>
                        <button
                          onClick={e => {
                            e.preventDefault();
                            setSelectedQuote(quote);
                            setShow(true);
                          }}
                          className="view-details-btn"
                        >
                          View Details
                        </button>
                      </TableCell>
                      <TableCell>
                        {quote.status === 'rejected' ? (
                          <>
                            <Badge className="rejected">{quote.status}</Badge>
                          </>
                        ) : (
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <button
                              onClick={e => {
                                e.preventDefault();
                                rejectQuote(quote?.id);
                              }}
                              className="cancel-btn"
                            >
                              <XMarkIcon
                                style={{ height: 20, marginRight: 5 }}
                              />
                              Decline{' '}
                            </button>
                            <button
                              onClick={e => {
                                e.preventDefault();
                                submitrequest(quote?.id)
                                setAcceptedQuoteID(quote?.id);
                                setAcceptedInsurerID(
                                  quote?.insurance_company.id
                                );
                                setAcceptedProxyID(quote?.proxy.id);
                              }}
                              className="accept-btn"
                            >
                              <CheckIcon
                                style={{ height: 20, marginRight: 5 }}
                              />
                              Accept{' '}
                            </button>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestDetails2;
