import React, { Component, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BiArrowBack, BiDownArrow } from 'react-icons/bi';
import Table from 'react-bootstrap/Table';
import './index.css';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { LOAD_COMPANIES, NEW_REQUEST_NOT_QUOTED } from 'src/Graphql/Queries';
import { formatDate } from 'src/utils/FormatDate';
import { formatCurrency } from 'src/utils/FormatCurrency';
import { useQuery, useMutation, useLazyQuery, gql } from '@apollo/client';
import customLoading from '../../../assets/img/logo_animation.gif';
import { useAppContext } from 'src/Context';
import {
  BROKER_CREATE_QUOTE,
  SEND_NOTIFICATION_CUSTOMER
} from 'src/Graphql/Mutations';
import { getToday } from 'src/utils/FormatDate';
import {
  ErrorNotification,
  SuccessNotification
} from 'src/components/Notification';
import axios from 'axios';
// import { convertWildcardStringToRegExp } from 'src/utils/FormatCurrency';
// import { GET_ALL_COMPANIES_FOR_INTERMEDIARY } from 'src/Graphql/Queries';
// import { Form } from 'react-bootstrap';

import {
  Box,
  Card,
  CardContent,
  Container,
  CardHeader,
  Checkbox,
  colors,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: 500,
    width: 828,
    marginTop: 50,
    borderRadius: 10
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  },
  active: {
    backgroundColor: '#2E79E9',
    height: 82,
    width: 260,
    color: 'white',
    '& $title': {
      marginTop: 5,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 15
    },
    '& $icon': {
      color: 'white',
      width: 22,
      height: 49
    }
  }
}));

const GET_REQUEST_DETAILS = gql`
  query getRequestByRequestID($requestID: uuid) {
    requests(where: { id: { _eq: $requestID } }) {
      motor_details
      home_details
      customer_id
    }
  }
`;

const GET_MOTOR_TYPES = gql`
 query getInsurers {
  request_types(where: {name: {_eq: "Motor"}}) {
    properties
  }
}
`;

const GET_PROXY_INSURER_COMMISSION = gql`
  query getProxyCommissions($proxyID: uuid, $_in: [uuid]!) {
    proxy_commissions(
      where: {
        proxy_id: { _eq: $proxyID }
        insurance_company_id: { _in: $_in }
      }
    ) {
      broker_commission
      insurance_company_id
    }
  }
`;

const GET_UPLOADED_FILE = gql`
  query getFileByReference($reference: String) {
    files(where: { reference_id: { _eq: $reference } }) {
      id
      key
      type
      reference_id
      properties
    }
  }
`;

const BrokerQuoteDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const rowdata = location.state;
  const [file, setFile] = useState(null);

  const { data: requestDetails } = useQuery(GET_REQUEST_DETAILS, {
    variables: {
      requestID: rowdata?.id
    },
    onCompleted: data => {
      // console.log(data)
    }
  });

  const {} = useQuery(GET_UPLOADED_FILE, {
    variables: {
      reference: rowdata?.request_id
    },
    onCompleted: data => {
      setFile(data.files[0]);
    }
  });

  const context = useAppContext();
  const { user } = context;
  const [insurerIDs, setInsurerIDs] = useState([]);
  

  const {loading, data:m_data } = useQuery(GET_MOTOR_TYPES);


  const [getCommissions] = useLazyQuery(GET_PROXY_INSURER_COMMISSION, {
    onCompleted: data => {
      setCommissions(data.proxy_commissions);
      // console.log(data)
    },
    onError: error => {
      console.log(error);
    }
  });

  useEffect(() => {
    if (insurerIDs.length > 0) {
      getCommissions({
        variables: {
          proxyID: user?.id,
          _in: insurerIDs
        }
      });
    }
  }, [insurerIDs]);


  return (
    <>
      {requestDetails ? (
        <div style={{ padding: 30 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            {requestDetails?.requests[0]?.home_details ? (
              <h2>Home Owner Insurance Details</h2>
            ) : (
              <h2>
                {/* {requestDetails?.requests[0]?.motor_details.motorInsuranceType}{' '} */}
                Motor Insurance Details
              </h2>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Button variant="outline-info" onClick={() => navigate(-1)}>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 30,
                    marginRight: 10
                  }}
                >
                  <BiArrowBack style={{ marginRight: 10 }} /> Go Back
                </span>
              </Button>{' '}
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              {/* <h6 style={{ color: "#0E3E86" }}>Requested by: {rowdata.requestby}</h6> */}
            </div>
            {requestDetails?.requests[0]?.home_details ? (
              <HomeOwner rowdata={requestDetails?.requests[0]} />
            ) : (
              <div
                style={
                  {
                    // overflowY: 'scroll',
                    // overflowX: 'hidden',
                    // maxHeight: 500
                  }
                }
              >
                <div
                  style={{
                    position: 'relative',
                    paddingTop: '1%',
                    width: '80%',
                    color: '#0E3E86',
                    width: 'inherit'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Table
                      bordered
                      style={{
                        width: '48%'
                      }}
                    >
                      <tbody>
                        <tr>
                          <td> Preferred Policy Cover</td>
                          <th>
                            {
                             !loading && m_data?.request_types[0]?.properties?.products.find((el)=>el.id ===  requestDetails?.requests[0].motor_details?.insurance_product_id)?.name
                             
                            }
                          </th>
                        </tr>
                        <tr>
                          <td>Vehicle Make</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.vehicle_make
                            }
                          </th>
                        </tr>
                        <tr>
                          <td>Vehicle Model</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.model_id
                            }
                          </th>
                        </tr>
                        <tr>
                          <td>Vehicle Value</td>
                          <th>
                            {formatCurrency(
                              requestDetails?.requests[0]?.motor_details
                                ?.vehicle_value
                                || requestDetails?.requests[0]?.motor_details?.vehicle_cost
                                || requestDetails?.requests[0]?.motor_details?.cost_price
                            )}
                          </th>
                        </tr>
                        <tr>
                          <td>Year of Registration</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.year_of_registration
                            }
                          </th>
                        </tr>
                        <tr>
                          <td>Do you want buy back excess?</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.buy_back_excess === true? "YES" : "NO"
                            }
                          </th>
                        </tr>
                        {/* <tr>
                          <td>Registration Number</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.registration_number
                            }
                          </th>
                        </tr> */}
                        {/* <tr>
                          <td>Chasis Number</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.chassis_number
                            }
                          </th>
                        </tr>
                        <tr>
                          <td>Engine Capacity</td>
                          <th>
                            {
                              requestDetails?.requests[0]?.motor_details
                                ?.engine_capacity
                            }
                            L
                          </th>
                        </tr> */}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            )}

            <hr />

            <p style={{ float: 'right', fontWeight: 'bold', color: '#0E3E86' }}>
              Requested By: {rowdata?.customer_name}
            </p>
          </div>
        </div>
      ) : (
        <img
          src={customLoading}
          style={{ position: 'relative', marginTop: '20%', left: '45%' }}
        />
      )}
    </>
  );
};

export default BrokerQuoteDetails;

export const HomeOwner = ({ rowdata }) => {
  const tabs = [];

  rowdata?.home_details.policy_options.map(option => {
    tabs.push(option.insurance_product_id);
  });

  if (rowdata?.home_details.purchase_for_self === 'No') {
    tabs.push('Beneficiary Info');
  }

  const [activeTab, setActiveTab] = React.useState(tabs[0]);

  const tableGrid = {
    display: 'flex',
    alignItems: 'center'
  };

  const table = {
    border: '1px solid #919090',
    width: '40%',
    height: '100px',
    marginTop: '10px',
    paddingLeft: '10px'
    // margin: "0 10px",
  };

  const th = {
    borderBottom: '1px solid #919090',
    borderLeft: '1px solid #919090',
    paddingLeft: '20px'
  };

  const tr = {
    borderBottom: '1px solid #919090'
  };

  const td = {
    borderLeft: '1px solid #919090',
    textAlign: 'left',
    paddingLeft: '20px'
  };

  const HeaderTitle = {
    fontSize: '1.5rem',
    textTransform: 'capitalize',
    color: '#1e40b7',
    fontWeight: 'bold'
  };

  const button = {
    backgroundColor: '#1e50ff',
    padding: '9px 9px',
    color: '#fff',
    fontWeight: '600',
    fontSize: '16px',
    borderRadius: '10px'
  };

  const footerText = {
    fontSize: '16px',
    textTransform: 'capitalize',
    color: '#919090'
  };

  const Header = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 15px'
  };

  const Tabs = {
    display: 'flex',
    alignItems: 'center'
  };

  const Tab = {
    padding: '8px 8px',
    textTransform: 'capitalize',
    color: '#00bcd4'
  };

  const ActiveTab = {
    color: '#000',
    fontWeight: '700',
    textTransform: 'capitalize',
    bordertop: '1px solid #ccc',
    padding: '8px 8px'
  };

  const line = {
    width: '100%',
    height: '1px',
    backgroundColor: '#ccc',
    margin: '10px 0'
  };

  return (
    <div style={{ marginTop: 10, width: 'inherit' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Table
          bordered
          style={{
            width: '48%'
          }}
        >
          <tbody>
            <tr>
              <td>Physical Address of Home</td>
              <th>{rowdata?.home_details?.physical_address}</th>
            </tr>
            <tr>
              <td>Digital Address of Home</td>
              <th>{rowdata?.home_details?.digital_address}</th>
            </tr>
          </tbody>
        </Table>
        <Table
          className="detail"
          bordered
          style={{
            width: '48%'
          }}
        >
          <tbody>
            <tr>
              <td>Proposed Policy Start Date</td>
              <th>{formatDate(rowdata?.home_details?.inception_date)}</th>
            </tr>
            <tr>
              <td>Proposed Policy End Date</td>
              <th>{formatDate(rowdata?.home_details?.expiry_date)}</th>
            </tr>
          </tbody>
        </Table>
      </div>
      <div style={{ padding: '0 1px', marginBottom: 15 }}>
        <div style={Tabs}>
          {tabs.map((item, index) => (
            <Button
              variant="outline-info"
              style={{ height: 50, marginRight: 5 }}
              key={index}
              onClick={() => setActiveTab(item)}
            >
              <p style={activeTab === item ? ActiveTab : Tab}>{item}</p>
            </Button>
          ))}
        </div>
      </div>

      <div style={tableGrid}>
        {activeTab === 'Rent' ? (
          <>
            <Table bordered>
              <tbody>
                <tr>
                  <td>Has Previous Rent Insurance</td>
                  <th>{rowdata?.home_details?.have_been_rent_insured}</th>
                </tr>

                <tr>
                  <td>Previous Rent Policy</td>
                  <th>{rowdata?.home_details?.prev_rent_policy}</th>
                </tr>
                <tr>
                  <td>Preferred Rent Assured Amount</td>
                  <th>
                    {formatCurrency(rowdata?.home_details?.rent_sum_assured)}
                  </th>
                </tr>
              </tbody>
            </Table>
          </>
        ) : activeTab === 'Building' ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Table bordered>
                <tbody>
                  <tr>
                    <td>Previous Building Insurance</td>
                    <th>{rowdata?.home_details?.prev_building_insurance}</th>
                  </tr>
                  <tr>
                    <td>Type of Building</td>
                    <th>{rowdata?.home_details?.building_type}</th>
                  </tr>
                  <tr>
                    <td>Building Color</td>
                    <th>{rowdata?.home_details?.building_color}</th>
                  </tr>
                  <tr>
                    <td>Construction Year of Building</td>
                    <th>{rowdata?.home_details?.construction_year}</th>
                  </tr>
                  <tr>
                    <td>Is the building residential?</td>
                    <th>{rowdata?.home_details?.is_residential}</th>
                  </tr>
                  <tr>
                    <td>Is business conducted in the building?</td>
                    <th>{rowdata?.home_details?.is_for_business}</th>
                  </tr>
                  <tr>
                    <td>
                      Does the building have interest from any financial institution?
                    </td>
                    <th>
                      {
                        rowdata?.home_details
                          ?.has_financial_institution_interest
                      }
                    </th>
                  </tr>
                </tbody>
              </Table>
              <Table bordered>
                <tbody>
                  <tr>
                    <td>Do you have any financial interest in the building?</td>
                    <th>{rowdata?.home_details?.has_financial_interest}</th>
                  </tr>
                  <tr>
                    <td>Materials used for building construction</td>
                    <th>
                      <ul class="list-group" style={{ marginTop: 10 }}>
                        {rowdata?.home_details?.building_materials?.map(
                          material => {
                            return <li class="list-group-item">{material}</li>;
                          }
                        )}
                      </ul>
                    </th>
                  </tr>
                  <tr>
                    <td>Materials used for roof construction</td>
                    <th>
                      <ul class="list-group" style={{ marginTop: 10 }}>
                        {rowdata?.home_details?.roofing_materials?.map(
                          material => {
                            return <li class="list-group-item">{material}</li>;
                          }
                        )}
                      </ul>
                    </th>
                  </tr>
                  <tr>
                    <td>Do you want to insure your walls?</td>
                    <th>{rowdata?.home_details?.insure_walls}</th>
                  </tr>
                  <tr>
                    <td>Value of wall</td>
                    <th>
                      {formatCurrency(
                        rowdata?.home_details?.wall_rebuilding_cost
                      )}
                    </th>
                  </tr>
                  <tr>
                    <td>Value of building</td>
                    <th>
                      {formatCurrency(rowdata?.home_details?.rebuidling_cost)}
                    </th>
                  </tr>
                </tbody>
              </Table>
            </div>
          </>
        ) : activeTab === 'Content' ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Table bordered>
                <tbody>
                  <tr>
                    <td>Has Previously insured content</td>
                    <th>
                      {rowdata?.home_details?.have_insured_content_before}
                    </th>
                  </tr>
                  <tr>
                    <td>Previous Content Insurance</td>
                    <th>{rowdata?.home_details?.prev_content_insurance}</th>
                  </tr>
                  <tr>
                    <td>Preferred Content Assured Amount</td>
                    <th>
                      {formatCurrency(
                        rowdata?.home_details?.content_sum_assured
                      )}
                    </th>
                  </tr>
                </tbody>
              </Table>
              <Table>
                <tbody>
                  <tr>
                    <td>Persons you want to insure</td>
                    <td>
                      <Table bordered>
                        <tr>
                          <th>Name</th>
                          <th>Sum Assured</th>
                          <th>Occupation</th>
                        </tr>
                        {rowdata?.home_details?.content_people?.map(
                          (val, key) => {
                            return (
                              <tr key={key} style={tr}>
                                <td style={td}>{val.name}</td>
                                <td style={td}>{val.sum_assured}</td>
                                <td style={td}>{val.occupation}</td>
                              </tr>
                            );
                          }
                        )}
                      </Table>
                    </td>
                  </tr>
                  <tr>
                    <td>Content you want to insure</td>
                    <td>
                      <Table bordered>
                        <tr>
                          <th>Item Name</th>
                          <th>Item Value</th>
                        </tr>
                        {rowdata?.home_details?.content_items?.map(
                          (val, key) => {
                            return (
                              <tr key={key} style={tr}>
                                <td style={td}>{val.name}</td>
                                <td style={td}>{val.value}</td>
                              </tr>
                            );
                          }
                        )}
                      </Table>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </>
        ) : activeTab === 'Personal Accident' ? (
          <>
            <Table bordered>
              <tbody>
                <tr>
                  <td>Has Previously insured for Personal Accident?</td>
                  <th>{rowdata?.home_details?.have_been_personally_insured}</th>
                </tr>
                <tr>
                  <td>Previous Content Insurance</td>
                  <th>
                    {rowdata?.home_details?.prev_personal_accident_policy}
                  </th>
                </tr>
                <tr>
                  <td>Preferred Personal Accident Assured Amount</td>
                  <th>
                    {formatCurrency(
                      rowdata?.home_details?.personal_accident_sum_assured
                    )}
                  </th>
                </tr>
              </tbody>
            </Table>
          </>
        ) : activeTab === 'Public Liability' ? (
          <>
            <Table bordered>
              <tbody>
                <tr>
                  <td>Has Previously insured for Public Liability?</td>
                  <th>
                    {rowdata?.home_details?.have_been_public_liability_insured}
                  </th>
                </tr>
                <tr>
                  <td>Previous Public Liability Insurance</td>
                  <th>{rowdata?.home_details?.prev_public_liability_policy}</th>
                </tr>
                <tr>
                  <td>Preferred Public Liability assured Amount</td>
                  <th>
                    {formatCurrency(
                      rowdata?.home_details?.public_liability_sum_assured
                    )}
                  </th>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <>
            <Table bordered>
              <tbody>
                <tr>
                  <td>Beneficiary Name</td>
                  <th>{rowdata?.home_details?.beneficiary_name} </th>
                </tr>
                <tr>
                  <td>Beneficiary Email</td>
                  <th>{rowdata?.home_details?.beneficiary_email} </th>
                </tr>
                <tr>
                  <td>Beneficiary Phone Number</td>
                  <th>{rowdata?.home_details?.beneficiary_phone_number} </th>
                </tr>
                <tr>
                  <td>Beneficiary National ID</td>
                  <th>{rowdata?.home_details?.beneficiary_id} </th>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </div>
  );
};
